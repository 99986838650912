// admin/src/Component/Administrateur/Create.js

import React from 'react';
import { BooleanInput, Create, SimpleForm, TextInput, email, required, AutocompleteInput, ReferenceInput } from 'react-admin';

const validateEmail = [required(), email()];

export const FleuristeCreate = (props) => (
    <Create { ...props }>
        <SimpleForm>
            <TextInput source="nom" label="Nom du fleuriste" validate={ required() }/>
            <TextInput source="adresseMail" label="Email" validate={ validateEmail } />
            <TextInput source="prenomContact" label="Prénom contact"/>
            <TextInput source="nomContact" label="Nom contact" />
            <TextInput source="numTel" label="Téléphone" />

            <ReferenceInput
                source="adresse"
                reference="adresses"
                label="Adresse"
                validate={required()}
                filterToQuery={searchText => ({ fullAdresse: searchText })}
                perPage={10000}
            >
                <AutocompleteInput optionText="fullAdresse" options={{
                    suggestionsContainerProps: { disablePortal: true, modifiers: { keepTogether: { enabled: true } } }
                }}
                />
            </ReferenceInput>

            <BooleanInput source="application" label="Utilise l'application (envoi de mail sinon)" defaultValue={true}/>

        </SimpleForm>
    </Create>
);

