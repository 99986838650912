// admin/src/Component/Administrateur/Show.js
import React from 'react';
import { Show,TabbedShowLayout, Tab, ReferenceArrayField, ChipField, SingleFieldList, ShowButton, EditButton, ReferenceManyField, Datagrid, DateField, TextField, EmailField, BooleanField } from 'react-admin';

export const ClientShow = (props) => (
    <Show { ...props }>
        <TabbedShowLayout>
            <Tab label="Infos client">
                <EmailField source="adresseMail" label="Email" />
                <TextField source="fullname" label="Nom complet"/>
                <TextField source="prenom" label="Prénom"/>
                <TextField source="nom" label="Nom"/>
                <TextField source="entreprise" label="Entreprise" />
                <TextField source="numTel" label="Téléphone"/>
                <DateField source="createdAt" label="Date de création" showTime/>
                <DateField source="updatedAt" label="Date de mise à jour" showTime/>
                <BooleanField source="acceptSms" label="SMS"/>
                <BooleanField source="estEntreprise" label="Pro"/>
            </Tab>
            <Tab label="Commandes payées">
                <ReferenceManyField label="Client payeur" reference="commandes" target="clientPaye_id">
                    <Datagrid>

                        <TextField source={"idCommandeShopify"} />
                        <DateField source={"dateLivraison"} options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }} label="Date de la première livraison"/>

                        <ShowButton label="Show"/>
                        <EditButton label="Edit"/>
                    </Datagrid>
                </ReferenceManyField>
            </Tab>
            <Tab label="Commandes reçues" >
                <ReferenceManyField label="Client destinataire" reference="commandes" target="clientDestinataire_id">
                    <Datagrid>

                        <TextField source={"idCommandeShopify"} />
                        <DateField source={"dateLivraison"} options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }} label="Date de la première livraison"/>

                        <ShowButton label="Show"/>
                        <EditButton label="Edit"/>
                    </Datagrid>
                </ReferenceManyField>
            </Tab>
            <Tab label="Adresses du client" >
                <ReferenceArrayField label="Adresses du client" reference="adresses" source="adresses_livr">
                    <SingleFieldList>
                        <ChipField source="fullAdresse" />
                    </SingleFieldList>
                </ReferenceArrayField>
            </Tab>
        </TabbedShowLayout>
    </Show>
);
