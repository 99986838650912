import React from 'react';
import compose from 'recompose/compose';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/Info';
import CodeIcon from '@material-ui/icons/Code';
import { withStyles } from '@material-ui/core/styles';
//import { translate } from 'react-admin';

const username = localStorage.getItem('username');

const styles = {
    media: {
        height: '18em',
    },
};

const mediaUrl = `https://admin.fleursdici.fr/welcome-fleurs-${parseInt(
    Math.random() * 3) + 1}.jpg`;

const Welcome = ({ classes }) => (
    <Card>
        <CardMedia image={mediaUrl} className={classes.media} />
        <CardContent>
            <Typography variant="headline" component="h2">
                Bienvenue { username }
            </Typography>
            <Typography component="p">
                Vous pouvez administrer les commandes et les livraisons.
            </Typography>
        </CardContent>
        <CardActions style={{ justifyContent: 'flex-end' }}>
            <Button href="https://digitale.fleursdici.fr">
                <InfoIcon style={{ paddingRight: '0.5em' }} />
                API doc
            </Button>
            <Button href="https://github.com/FleursdIci/admin">
                <CodeIcon style={{ paddingRight: '0.5em' }} />
                Admin sources
            </Button>
        </CardActions>
    </Card>
);

const enhance = compose(
    withStyles(styles)
);

export default enhance(Welcome);
