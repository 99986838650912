// admin/src/Component/Administrateur/Edit.js

import React from 'react';
import { Edit, SimpleForm, TextInput, NumberInput, BooleanInput} from 'react-admin';



export const ProduitEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="nom" label="nom" />
            <TextInput source="codeproduit" label="Code Produit" />
            <NumberInput source="budgetfleuriste" label="Budget Fleuriste"/>
            <NumberInput source="budgetproducteur" label="Budget Producteur"/>
            <NumberInput source="bonusexpress" label="Bonus Express" />
            <TextInput source="composition" label="Composition" />
            <TextInput source="indicationtaille" label="Indication taille" />
            <BooleanInput source="vase" label="vase" />
        </SimpleForm>
    </Edit>
);