// admin/src/Component/ModeDL/Show.js
import React from 'react';
import { Show, SimpleShowLayout, TextField } from 'react-admin';

export const ModeDLShow = (props) => (
    <Show { ...props }>
        <SimpleShowLayout>
            <TextField source="nom" label="nom" />
            <TextField source="installation" label="Installation"/>
            <TextField label="Véhicule" source="vehicule"/>
            <TextField source="descriptionFleuriste" label="Description fleuriste"/>
        </SimpleShowLayout>
    </Show>
);