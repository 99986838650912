// in src/Dashboard.js
import React from 'react';

import compose from 'recompose/compose';
//import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
//import NoSsr from '@material-ui/core/NoSsr';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';

import Welcome from './Welcome';
//import NewOrders from './NewOrders';

import { Title, Responsive } from 'react-admin';
import ImportAction  from './Import';
import ExportAction  from './Export';
import SetStateCommandeAction  from './SetStateCommande';
import SetStateLivraisonAction  from './SetStateLivraison';
import ExportFleetersAction from './ExportFleeters';
import ExportLivreurAction from './ExportLivreur';
import MailFleuristeLivreurAction from './MailFleuristeLivreur';
import SmsDailySenderAction from './SmsDailySender';
import CreateCommandeAction  from './CreateCommandeAction';

//import dataProvider from './../../dataProvider';

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

function LinkTab(props) {
    return <Tab component="a" onClick={event => event.preventDefault()} {...props} />;
}

const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    flex: { display: 'flex' },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftCol: { flex: 1, marginRight: '1em' },
    rightCol: { flex: 1, marginLeft: '1em' },
    singleCol: { marginTop: '2em', marginBottom: '2em' },
});

class Dashboard extends React.Component {
    state = {
        value: 0,
    };

    // componentDidMount() {
    //     this.fetchData();
    // }
    //
    // componentDidUpdate(prevProps) {
    //     // handle refresh
    //     if (this.props.version !== prevProps.version) {
    //         this.fetchData();
    //     }
    // }
    //
    // fetchData() {
    //     this.fetchOrders();
    //     //this.fetchCustomers();
    // }


    handleChange = (event, value) => {
        this.setState({ value });
    };



    render() {
        const { classes } = this.props;
        const { value } = this.state;

        return (

            <Responsive
                medium={

                    <div className={classes.root}>
                        <Title title="Admin Fleurs d'Ici"/>
                        <AppBar position="static" color="secondary">
                            <Tabs variant="fullWidth" value={value} onChange={this.handleChange}>
                                <LinkTab label="Synthèse" href="page1"/>
                                <LinkTab label="Import / Export" href="page2"/>
                                <LinkTab label="Actions de l'admin" href="page3"/>
                                <LinkTab label="Créer une commande" href="page4"/>
                            </Tabs>
                        </AppBar>
                        {value === 0 &&
                        <TabContainer>
                            <div style={styles.flexColumn}>
                                <div style={styles.flex}>
                                    {/*<NewCustomers value={revenue} />*/}
                                    {/*<NewOrders value={nbNewOrders} />*/}
                                </div>
                                <div style={{ marginBottom: '2em' }}>
                                    <Welcome />
                                </div>
                            </div>
                        </TabContainer>}
                        {value === 1 && <TabContainer>
                            Importer des commandes au format csv : <br/> <ImportAction/> <br/><br/>
                            Exporter des livraisons au format csv. <br/>
                            Il faut choisir une periode pour l'exportation : <br/> <ExportAction/><br/><br/>
                            Exporter les missions de Fleeters : <br/> <ExportFleetersAction/> <br/><br/>
                            Exporter les livraisons des coursiers : <br/> <ExportLivreurAction/><br/><br/>
                            Envoyer les mails aux fleuristes et livreurs qui ne communiquent pas avec une application
                            : <br/> <MailFleuristeLivreurAction/><br/>
                        </TabContainer>}
                        {value === 2 && <TabContainer>
                            Mettre à jour l'état des commandes : <br/> <SetStateCommandeAction/> <br/>
                            Mettre à jour l'état des livraisons : <br/> <SetStateLivraisonAction/> <br/>
                            Envoyer les Sms.<br/>
                            Il faut choisir une periode pour l'envoi : <br/> <SmsDailySenderAction/>
                        </TabContainer>}
                        {value === 3 && <TabContainer>
                            <CreateCommandeAction/>
                        </TabContainer>}
                    </div>
                }
            />
        );
    }
}

Dashboard.propTypes = {
    classes: PropTypes.object.isRequired,
};

//const mapStateToProps = state => ({
//    version: state.admin.ui.viewVersion,
//});


export default compose(
    //connect(mapStateToProps),
    withStyles(styles),
 //   dataProvider
)(Dashboard);

