// in src/SetStateLivraisonAction.js

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { showNotification } from 'react-admin';
import { push } from 'react-router-redux';

const request_uri = `${process.env.REACT_APP_API_ENTRYPOINT}/setLivraisonState`;

class SetStateLivraisonAction extends Component {
    constructor(props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit (e) {

        e.preventDefault();

        const { push, showNotification }  = this.props;

        const token = localStorage.getItem('token');

        const headers = new Headers({'Authorization': 'Bearer ' + token });


        fetch(`${request_uri}`, { method: 'GET', headers: headers })
            .then(response =>
                response.json().then(data => ({
                        data: data,
                        status: response.status
                    })
                ).then(res => {

                    if (res.data["La reponse"] === "livraisons mises a jour") {
                        console.log("on a un resultat " + res.data);
                        showNotification('Succès, les Livraisons ont bien été traitées.');
                        push('/livraisons');
                    }
                    else {
                        console.log("on a un echec");
                        showNotification('Erreur: échec de la mise à jour', 'warning');
                    }


                    }
                )).catch((e) => {
                console.error(e);
                showNotification('Erreur: échec de la mise à jour', 'warning');
            });
    }

    render() {
        return (
        <form>
            <br/>
            <Button variant="contained" color="primary"  label="Mise à jour de l'état des livraisons" onClick={this.handleSubmit}>Mise à jour de l'état des livraisons</Button>
        </form>);
    }
}

SetStateLivraisonAction.propTypes = {
    push: PropTypes.func,
    showNotification: PropTypes.func,
};

export default connect(null, {
    showNotification,
    push,
})(SetStateLivraisonAction);