import green from '@material-ui/core/colors/green';
import orange from '@material-ui/core/colors/orange';
import red from '@material-ui/core/colors/red';

const rowStyle = (record, index, defaultStyle = {}) => {
    if (record.etatgeneral === 'avenir')
        return {
            ...defaultStyle,
            borderLeftColor: green[500],
            borderLeftWidth: 5,
            borderLeftStyle: 'solid',
        };
    if (record.etatgeneral === 'probleme')
        return {
            ...defaultStyle,
            borderLeftColor: orange[500],
            borderLeftWidth: 5,
            borderLeftStyle: 'solid',
        };
    if (record.etatgeneral === 'terminee')
        return {
            ...defaultStyle,
            borderLeftColor: red[500],
            borderLeftWidth: 5,
            borderLeftStyle: 'solid',
        };
    if (record.etatgeneral === 'annulee')
        return {
            ...defaultStyle,
            borderLeftColor: 'black',
            borderLeftWidth: 5,
            borderLeftStyle: 'solid',
        };
    return defaultStyle;
};

export default rowStyle;