// admin/src/Component/Administrateur/List.js

import React from 'react';
import { Filter, TextInput, Pagination, List, Datagrid, DateInput, TextField, EmailField, DateField, ShowButton, EditButton, BooleanField } from 'react-admin';
//import { CustomPagination } from '../Pagination/CustomPagination';
import SimpleArray from '../../Utils/SimpleArray';

const AdminFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Username" source="username" />
        <TextInput label="Email" source="email" />
        <DateInput label="Créé avant le" source="createdAt[before]"/>
        <DateInput label="Créé après le" source="createdAt[after]"/>
    </Filter>
);

export const AdministrateurList = (props) => (
    <List {...props} title="Administrateurs" pagination={ <Pagination rowsPerPageOptions={[5, 10, 20]}/> }  perPage={ 10 } filters={<AdminFilter />}>
        <Datagrid>
            <TextField source="originId" label="ID"/>
            <EmailField source="email" label="Email" />
            <TextField source="username" label="Non d'utilisateur"/>
            <BooleanField source="isActive" label="Activé"/>
            <DateField source="createdAt" label="Date de création"/>
            <DateField source="updatedAt" label="Dernière mise à jour"/>

            <SimpleArray source="roles" label="Roles" sortable={false}/>

            <ShowButton label="Voir" />
            <EditButton label="Modifier" />
        </Datagrid>
    </List>
);


