// admin/src/Component/Fleuriste/List.js

import React from 'react';
import { Filter, TextInput, Pagination, List, Datagrid, TextField, EmailField, ReferenceField, ShowButton, EditButton, BooleanField } from 'react-admin';
//import { CustomPagination } from '../Pagination/CustomPagination';

const AdminFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Nom" source="nom" />
        <TextInput label="Nom contact" source="nomContact" />
        <TextInput label="Email" source="adresseMail" />
    </Filter>
);

export const FleuristeList = (props) => (
    <List {...props} title="Fleuristes" pagination={ <Pagination rowsPerPageOptions={[5, 10, 20]} /> }  perPage={ 10 } filters={<AdminFilter />}>
        <Datagrid>
            <TextField source="nom" label="Nom fleuriste"/>
            <EmailField source="adresseMail" label="Email" />
            <TextField source="prenomContact" label="Prenom contact"/>
            <TextField source="nomContact" label="Nom contact"/>

            <TextField source="numTel" label="Téléphone" sortable={false}/>

            <ReferenceField source="adresse" reference="adresses" label="Adresse">
                <TextField source={"fullAdresse"}/>
            </ReferenceField>

            <BooleanField source="useapp" label="Utilise l'application"/>

            <ShowButton label="Voir" />
            <EditButton label="Modifier" />
        </Datagrid>
    </List>
);


