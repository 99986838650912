// admin/src/Component/ModeDL/List.js

import React from 'react';
import { Pagination, List, Datagrid, TextField, ShowButton, EditButton } from 'react-admin';
//import { CustomPagination } from '../Pagination/CustomPagination';

export const ModeDLList = (props) => (
    <List {...props} title="Modes De Livraisons" pagination={ <Pagination rowsPerPageOptions={[5, 10]}/> }  perPage={ 10 }>
        <Datagrid>
            <TextField source="nom" label="nom" />
            <TextField source="installation" label="Installation"/>
            <TextField label="Véhicule" source="vehicule"/>
            <TextField source="descriptionFleuriste" label="Description fleuriste"/>

            <ShowButton label="Voir" />
            <EditButton label="Modifier" />
        </Datagrid>
    </List>
);


