// admin/src/Component/Administrateur/Edit.js

import React from 'react';
import { Edit, SimpleForm, TextInput, NumberInput, ReferenceInput, SelectInput} from 'react-admin';



export const OffreEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="nom" label="Nom" />
            <NumberInput source="prix" label="Prix"/>
            <NumberInput source="frequence" label="Fréquence"/>
            <NumberInput source="duree" label="Durée"/>
            <ReferenceInput source="produit" reference="produits" label="Produit">
                <SelectInput optionText="nom" />
            </ReferenceInput>
            <ReferenceInput source="modeDeLivraison" reference="mode_de_livraisons" label="Mode de livraison">
                <SelectInput optionText="nom"/>
            </ReferenceInput>
            <TextInput source="sku" label="SKU"/>
        </SimpleForm>
    </Edit>
);