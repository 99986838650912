// src/authProvider.js
import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK } from 'react-admin';
import decodeJwt from 'jwt-decode';

// Change this to be your own login check route.
const login_uri = `${process.env.REACT_APP_API_ENTRYPOINT}/login_check`;

export default (type, params) => {
    switch (type) {
        case AUTH_LOGIN:
            const { username, password } = params;
            const request = new Request(`${login_uri}`, {
                method: 'POST',
                body: JSON.stringify({ username: username, password: password }),
                headers: new Headers({ 'Content-Type': 'application/json' }),
            });

            return fetch(request)
                .then(response => {
                    if (response.status < 200 || response.status >= 300) throw new Error(response.statusText);

                    return response.json();
                })
                .then(({ token }) => {
                    const decodedToken = decodeJwt(token);
                    localStorage.setItem('token', token); // The JWT token is stored in the browser's local storage
                    localStorage.setItem('roles', decodedToken.roles);
                    localStorage.setItem('username', decodedToken.username);
                });

        case AUTH_LOGOUT:
            localStorage.removeItem('token');
            localStorage.removeItem('roles');
            localStorage.removeItem('username');
            return Promise.resolve();

        case AUTH_ERROR:
            if (401 === params.status || 403 === params.status) {
                localStorage.removeItem('token');
                localStorage.removeItem('roles');
                localStorage.removeItem('username');
                return Promise.reject();
            } else if (404 === params.status || 400 === params.status) {
                return Promise.resolve();
            }

            return Promise.reject();
        case AUTH_CHECK:
            // verify if token has already expired
            const decodedToken = localStorage.getItem('token') ? decodeJwt(localStorage.getItem('token')) : false;
            return (decodedToken && decodedToken.exp > new Date().getTime()/1000) ? Promise.resolve() : Promise.reject();

        default:
            return Promise.resolve();
    }
}
