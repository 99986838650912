// admin/src/Component/Fleuriste/Edit.js

import React from 'react';
import { BooleanInput, Edit, SimpleForm, TextInput, email, required, ReferenceInput, AutocompleteInput} from 'react-admin';

const validateEmail = [required(), email()];

export const FleuristeEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="nom" label="Nom du Fleuriste" validate={ required() }/>
            <TextInput source="adresseMail" label="Email" validate={ validateEmail } />
            <TextInput source="prenomContact" label="Prénom contact"/>
            <TextInput source="nomContact" label="Nom contact" />
            <TextInput source="numTel" label="Téléphone" />

            <ReferenceInput
                source="adresse"
                reference="adresses"
                label="Adresse"
                validate={required()}
                filterToQuery={searchText => ({ fullAdresse: searchText })}
                perPage={10000}
            >
                <AutocompleteInput optionText="fullAdresse" options={{
                    suggestionsContainerProps: { disablePortal: true, modifiers: { keepTogether: { enabled: true } } }
                }}
                />
            </ReferenceInput>

            <BooleanInput source="useapp" label="Utilise l'application (envoi de mail sinon)" />

        </SimpleForm>
    </Edit>
);