// admin/src/Component/Administrateur/Show.js
import React from 'react';
import { Show, SimpleShowLayout, TextField } from 'react-admin';
//import Typography from '@material-ui/core/Typography';

// const Position = (props) => (
//     `[${props.record.latitude}, ${props.record.longitude}]`)


export const AdresseShow = ({...props}) => (
    <Show { ...props }>

        <SimpleShowLayout>
            <TextField source="voie" label="Voie" />
            <TextField source="ville" label="Ville"/>
            <TextField source="codePostal" label="Code Postal"/>
            <TextField source="pays" label="Pays"/>
            <TextField source="infoSup" label="Infos"/>
            <TextField source="latitude" label="Latitude"/>
            <TextField source="longitude" label="Longitude"/>
        </SimpleShowLayout>

    </Show>
);


