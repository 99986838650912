// admin/src/Component/Livreur/Edit.js

import React from 'react';
import { Edit, SimpleForm, TextInput, BooleanInput, email} from 'react-admin';



export const LivreurEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="adresseMail" label="Email" validate={ email() } />
            <TextInput source="nom" label="Nom"/>
            <TextInput source="telephone" label="Téléphone"/>
            <BooleanInput source="application" label="Utilise l'application (envoi de mail sinon)" />
        </SimpleForm>
    </Edit>
);