// admin/src/Component/Fleuriste/Show.js
import React from 'react';
import { Show, SimpleShowLayout, TextField, EmailField, ReferenceField, BooleanField } from 'react-admin';

export const FleuristeShow = (props) => (
    <Show { ...props }>
        <SimpleShowLayout>
            <TextField source="nom" label="Nom fleuriste"/>
            <EmailField source="adresseMail" label="Email" />
            <TextField source="prenomContact" label="Prenom contact"/>
            <TextField source="nomContact" label="Nom contact"/>

            <TextField source="numTel" label="Téléphone" sortable={false}/>

            <ReferenceField source="adresse" reference="adresses" label="Adresse">
                <TextField source={"fullAdresse"}/>
            </ReferenceField>

            <BooleanField source="useapp" label="Utilise l'application"/>

        </SimpleShowLayout>
    </Show>
);