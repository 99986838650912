// in src/ExportAction.js
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { showNotification, SelectInput, ReferenceInput } from 'react-admin';
import { reduxForm } from 'redux-form';
import { push } from 'react-router-redux';

const request_uri = `${process.env.REACT_APP_API_ENTRYPOINT}/communiqueMail/`;

const formatIriAsInt = v => {
    if (v.indexOf('/') >= 0) {
        v = v.split('/');

        return parseInt(v[v.length - 1]);
    }

    return v;
};

class MailFleuristeLivreurAction extends Component {
    constructor(props) {
        super(props);
        this.state = {
            periode: '',
            type: '',
            entity: '',
        };

        // this.fileInput = React.createRef();
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    // handleDataChange = (evt) => {
    //     this.setState({ data: evt.target.value });
    // }
    handleChange = event => {

        if (event.target.name === 'periode') {
            this.setState({entity: ''});
            this.setState({type: ''});
        }
        else if (event.target.name === 'type') {
                this.setState({entity: ''});
                this.setState({type: ''})
            }
        this.setState({[event.target.name]: event.target.value});
    };

    handleEntity = (event, entity) => {

    entity = formatIriAsInt(entity);
    this.setState({ entity: entity });
    console.log(`Nouvelle entity ${entity}`)
    };

    capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    handleSubmit (e) {

        e.preventDefault();

        const {showNotification} = this.props;

        const periode = this.state.periode;
        const type = this.state.type;
        let entity = "";
        this.state.entity === "" ? entity = "" : entity = "/" + this.state.entity;

        const token = localStorage.getItem('token');

        const headers = new Headers({'Authorization': 'Bearer ' + token});


        fetch(`${request_uri}${type}/${periode}${entity}`, {method: 'GET', headers: headers})

            .then(response =>
                response.json().then(data => ({
                        data: data,
                        status: response.status
                    })
                ).then(res => {

                        if (res.data["La reponse"].match("^Tous les commandes par mail ont été")) {
                            console.log("on a un resultat " + res.data["La reponse"]);
                            showNotification(res.data["La reponse"]);
                        }
                        else {
                            console.log("on a un echec");
                            showNotification('Erreur: échec d\'envoi des Sms', 'warning');
                        }


                    }
                )).catch((e) => {
            console.error(e);
            showNotification('Erreur: échec d\'envoi des Sms', 'warning');
        });
    }

    render() {
        const { periode } = this.state;
        const enabled =
            periode.length > 0;


        return (
            <form>
                <br/>
                <Select
                    value={this.state.periode}
                    onChange={this.handleChange}
                    inputProps={{
                        name: 'periode'
                    }}
                >
                    <MenuItem value=""></MenuItem>
                    <MenuItem value={'avenir'}>Au delà d'une semaine</MenuItem>
                    <MenuItem value={'semaine'}>Dans la semaine</MenuItem>
                    <MenuItem value={'troisjours'}>Dans trois jours</MenuItem>
                    <MenuItem value={'deuxjours'}>Dans deux jours</MenuItem>
                    <MenuItem value={'demain'}>Demain</MenuItem>
                    <MenuItem value={'aujourdhui'}>Aujourd'hui</MenuItem>

                    <MenuItem value={'terminee'}>Terminées</MenuItem>
                    <MenuItem value={'annulee'}>Annulées</MenuItem>


                </Select>
                <Select
                    value={this.state.type}
                    onChange={this.handleChange}
                    inputProps={{
                        name: 'type'
                    }}
                >
                    <MenuItem value=""></MenuItem>
                    <MenuItem value={'livreur'}>Livreur</MenuItem>
                    <MenuItem value={'fleuriste'}>Fleuriste</MenuItem>

                </Select>
                <br/><br/>
                {this.state.type !== '' &&

                <ReferenceInput source="entity"
                                //parse={v => v ? `/${this.state.type}s/` + v : v}
                                //format={this.formatIriAsInt}
                                reference={`${this.state.type}s`}
                                resource={`${this.state.type}s`}
                                label={this.capitalize(this.state.type)} allowEmpty={true}
                                onChange={this.handleEntity}
                >

                    <SelectInput optionText="nom"   />

                </ReferenceInput>

                }

                    <br /><br/>

                <Button variant="contained"  disabled={!enabled}  color="primary"  label="Export" onClick={this.handleSubmit}>Envoyer</Button>
            </form>);
    }

}

MailFleuristeLivreurAction.propTypes = {
    push: PropTypes.func,
    showNotification: PropTypes.func,
};

// Decorate the form component
MailFleuristeLivreurAction = reduxForm({
    form: 'mailfleuristelivreur' // a unique name for this form
})(MailFleuristeLivreurAction);

export default connect(null, {
    showNotification,
    push,
})(MailFleuristeLivreurAction);