// admin/src/Component/Administrateur/Create.js

import React from 'react';
import { Create, SimpleForm, TextInput, NumberInput, required, BooleanInput } from 'react-admin';

export const ProduitCreate = (props) => (
    <Create { ...props }>
        <SimpleForm>
            <TextInput source="nom" label="nom" validate={required()}/>
            <TextInput source="codeproduit" label="Code Produit" />
            <NumberInput source="budgetfleuriste" label="Budget Fleuriste"/>
            <NumberInput source="budgetproducteur" label="Budget Producteur"/>
            <NumberInput source="bonusexpress" label="Bonus Express" />
            <TextInput source="composition" label="Composition" />
            <TextInput source="indicationtaille" label="Indication taille" />
            <BooleanInput source="vase" label="vase" />
        </SimpleForm>
    </Create>
);

