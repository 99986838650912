// admin/src/Component/Administrateur/Show.js
import React from 'react';
import { Show, TabbedShowLayout, Datagrid, ShowButton, EditButton, Tab, TextField, ReferenceField, DateField, ReferenceManyField } from 'react-admin';

const etatsfutures = ['avenir', 'semaine', 'troisjours', 'deuxjours', 'demain', 'aujourdhui'];
//const etatspasses = ['terminee', 'annulee'];

const postRowStyle = (record) => ({
    backgroundColor: etatsfutures.includes(record.etatgeneral)  ? '#efe' : '#fee',
});



export const CommandeShow = (props) => (
    <Show { ...props }>
        <TabbedShowLayout>
            <Tab label="Résumé">

                <TextField source="idCommandeShopify" label="Shopify ID"/>

                <TextField label="Etat" source="etat"/>

                <TextField label="Type de commande" source="typeCommande"/>


                <DateField source="createdAt" label="Date de la création de la commande" showTime/>
                <DateField source="updatedAt" label="Date de la mise à jour de la commande" showTime/>

                <DateField source="dateLivraison" label="Date de la première livraison"/>

                <ReferenceField source="offre" reference="offres" label="Offre souscrite">
                    <TextField source={"nom"} />
                </ReferenceField>

                <TextField label="À propos de cette commande" source="annotationsFleursdici" />

            </Tab>


            <Tab label="Détails">

                <ReferenceField source="clientPaye" reference="clients" label="Client payeur">
                    <TextField source={"fullname"} />
                </ReferenceField>

                <ReferenceField source="clientDestinataire" reference="clients" label="Client destinataire">
                    <TextField source={"fullname"} />
                </ReferenceField>

                <ReferenceField source="adresseFacturation" reference="adresses" label="Adresse de facturation">
                    <TextField source={"fullAdresse"} />
                </ReferenceField>

                <ReferenceField source="adresseReception" reference="adresses" label="Adresse de livraison">
                    <TextField source={"fullAdresse"} />
                </ReferenceField>

                <TextField source="creneauLivraison" label="Créneau"/>

                <TextField source="petitMot" label="Petit Mot"/>

            </Tab>


            <Tab label="Livraisons">


                <ReferenceManyField
                    label="Livraisons de la commande"
                    target="commande"
                    reference="livraisons"
                >

                    <Datagrid
                        rowStyle={postRowStyle}

                    >

                <DateField
                    source={"date"}
                    options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }}
                    sortable={false}
                />
                        <ShowButton label="Voir" />
                        <EditButton label="Modifier" />
                    </Datagrid>

                </ReferenceManyField>


            </Tab>


        </TabbedShowLayout>
    </Show>
);
