// admin/src/Component/Client/List.js

import React from 'react';
import { Filter, TextInput, Pagination, List, Datagrid, TextField, EmailField, BooleanField, BooleanInput, ShowButton, EditButton } from 'react-admin';
//import { CustomPagination } from '../Pagination/CustomPagination';

const AdminFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Nom et/ou prénom" source="fullname" />
        <TextInput label="Email" source="adresseMail" />
        <TextInput label="Téléphone" source="numTel" />
        <BooleanInput label="SMS" source="acceptSms" />
        <BooleanInput label="Pro" source="estEntreprise" />
    </Filter>
);


export const ClientList = (props) => (
    <List {...props} title="Clients" pagination={ <Pagination rowsPerPageOptions={[5, 10, 20]}/> }  perPage={ 20 } filters={<AdminFilter />}>
        <Datagrid>
            <EmailField source="adresseMail" label="Email" />
            <TextField source="fullname" label="Nom complet"/>
            <TextField source="numTel" label="Téléphone" sortable={false}/>
            <TextField source="entreprise" label="Entreprise" />
            <BooleanField source="acceptSms" label="SMS"/>
            <BooleanField source="estEntreprise" label="Pro"/>
            <ShowButton label="Voir" />
            <EditButton label="Modifier" />
        </Datagrid>
    </List>
);


