// admin/src/Component/Administrateur/Show.js
import React from 'react';
import { Show, TabbedShowLayout, Tab, TextField, ReferenceField, DateField } from 'react-admin';

export const LivraisonShow = (props) => (
    <Show { ...props }>
        <TabbedShowLayout>
            <Tab label="Infos livraison">
            <ReferenceField source="commande" reference="commandes" label="Commande" allowEmpty={true}>
                <TextField source={"idCommandeShopify"} />
            </ReferenceField>

            <DateField source="date" label="Date de la livraison"/>
            <TextField source="creneau" label="Créneau"/>

            <TextField source="etatcontact" label="Etat contact"/>

            <TextField source="etatgeneral" label="Etat général"/>


            <ReferenceField source="destinataire" reference="clients" label="Client destinataire">
                <TextField source={"fullname"} />
            </ReferenceField>

            <ReferenceField source="adresseLivraison" reference="adresses" label="Adresse de livraison">
                <TextField source={"fullAdresse"} />
            </ReferenceField>



            <TextField source="telContact" label="Téléphone destinataire"/>

            <ReferenceField source="produit" reference="produits" label="Produit à livrer">
                <TextField source={"nom"} />
            </ReferenceField>


            <TextField source="petitMot" label="Petit mot"/>
            </Tab>
            <Tab label="Infos fleuriste">

                <TextField source="etatmissionfleuriste" label="Etat mission fleuriste"/>

                <ReferenceField source="fleuriste" reference="fleuristes" label="Fleuriste" allowEmpty={true}>
                    <TextField source={"nom"} />
                </ReferenceField>

                <TextField source="infosSuppFleuriste" label="Infos supplémentaires fleuriste"/>



            </Tab>
            <Tab label="Infos livreur">

                <TextField source="etatlivraison" label="Etat mission livreur"/>

                <ReferenceField source="livreur" reference="livreurs" label="Livreur" allowEmpty={true}>
                    <TextField source={"nom"} />
                </ReferenceField>

                <TextField source="infosSuppLivraison" label="Infos supplémentaires livreur"/>


            </Tab>
        </TabbedShowLayout>
    </Show>
);