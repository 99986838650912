// admin/src/Component/Parametre/Create.js

import React from 'react';
import { Create, SimpleForm, TextInput, required } from 'react-admin';

export const ParametreCreate = (props) => (
    <Create { ...props }>
        <SimpleForm>
            <TextInput source="nom" label="nom" validate={required()} />
            <TextInput source="valeur" label="valeur"/>
        </SimpleForm>
    </Create>
);

