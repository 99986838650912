// admin/src/Component/Commande/List.js

import React, { Fragment } from 'react';
import {
    Datagrid,
    SelectInput,
    TextInput,
    ReferenceField,
    DateField,
    DateInput,
    EditButton,
    ShowButton,
    Filter,
    List,
    Edit,
    SimpleForm,
    ReferenceInput,
    Responsive,
    TextField,
    Pagination,
} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
//import Icon from '@material-ui/icons/AttachMoney';
import Divider from '@material-ui/core/Divider';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
//import {CommandeEdit} from "./Edit";

//import NbItemsField from './NbItemsField';
//import CustomerReferenceField from '../visitors/CustomerReferenceField';
//import MobileGrid from './MobileGrid';

//export const CommandIcon = Icon;

const filterStyles = {
    status: { width: 150 },
};

const CommandEdit = props => (
    <Edit
        {...props}
        /* disable the app title change when shown */
        title=" "
    >
        <SimpleForm
            /* The form must have a name dependent on the record, because by default all forms have the same name */
            form={`command_edit_${props.id}`}
        >
            <SelectInput label="Etat de la commande" source="etat" choices={[
                { id: 'enregistree', name: 'enregistree' },
                { id: 'encours', name: 'encours' },
                { id: 'terminee', name: 'terminee' },
                { id: 'annulee', name: 'annulee' },
            ]}
            />
        </SimpleForm>
    </Edit>
);

const CommandFilter = withStyles(filterStyles)(({ classes, ...props }) => (
    <Filter {...props}>

        <TextInput label="Shopify ID" source="idCommandeShopify"/>

        <TextInput label="Client payeur" source="clientPaye_fullname" />

        <TextInput label="Téléphone payeur" source="clientPaye_numTel" />

        <TextInput label="Client destinataire" source="clientDestinataire_fullname" />

        <TextInput label="Téléphone destinataire" source="clientDestinataire_numTel" />

        <ReferenceInput label="Offre" source="offre" reference="offres" perPage={100}>
            <SelectInput optionText="nom" />
        </ReferenceInput>

        <DateInput label="Avant le" source="date[before]"/>

        <DateInput label="Après le" source="date[after]"/>



    </Filter>
));

const datagridStyles = {
    total: { fontWeight: 'bold' },
};

class TabbedDatagrid extends React.Component {
    tabs = [
        { id: 'enregistree', name: 'enregistree' },
        { id: 'encours', name: 'encours' },
        { id: 'terminee', name: 'terminee' },
        { id: 'annulee', name: 'annulee' },
    ];

    state = { enregistree: [], encours: [], terminee: [], annulee: [] };

    static getDerivedStateFromProps(props, state) {
        if (props.ids !== state[props.filterValues.etat]) {
            return { ...state, [props.filterValues.etat]: props.ids };
        }
        return null;
    }

    handleChange = (event, value) => {
        const { filterValues, setFilters } = this.props;
        setFilters({ ...filterValues, etat: value });
    };

    render() {
        const { classes, filterValues, ...props } = this.props;
        return (
            <Fragment>
                <Tabs
                    fullWidth
                    centered
                    value={filterValues.etat}
                    indicatorColor="primary"
                    onChange={this.handleChange}
                >
                    {this.tabs.map(choice => (
                        <Tab
                            key={choice.id}
                            label={choice.name}
                            value={choice.id}
                        />
                    ))}
                </Tabs>
                <Divider />
                <Responsive
                    medium={
                        <div>
                            {filterValues.etat === 'enregistree' && (
                                <Datagrid
                                    {...props}
                                    ids={this.state['enregistree']}
                                    expand={<CommandEdit />}
                                >
                                    <TextField source="idCommandeShopify" label="Id commande"/>

                                    <ReferenceField source="clientPaye" reference="clients" label="Client" >
                                        <TextField source={"fullname"} />
                                    </ReferenceField>

                                    <ReferenceField source="clientDestinataire" reference="clients" label="Destinataire">
                                        <TextField source={"fullname"} />
                                    </ReferenceField>

                                    <ReferenceField source="adresseReception" reference="adresses" label="Adresse de livraison">
                                        <TextField source={"fullAdresse"} />
                                    </ReferenceField>

                                    <ReferenceField source="offre" reference="offres" label="Offre souscrite">
                                        <TextField source={"nom"} />
                                    </ReferenceField>

                                    <DateField source="dateLivraison" label="Date de la première livraison"/>

                                    <TextField label="Type de commande" source="typeCommande"/>

                                    <ShowButton label="Voir" />
                                    <EditButton label="Modifier" />
                                </Datagrid>
                            )}
                            {filterValues.etat === 'encours' && (
                                <Datagrid
                                    {...props}
                                    ids={this.state['encours']}
                                    expand={<CommandEdit />}
                                >
                                    <TextField source="idCommandeShopify" label="Id commande"/>

                                    <ReferenceField source="clientPaye" reference="clients" label="Client" >
                                        <TextField source={"fullname"} />
                                    </ReferenceField>

                                    <ReferenceField source="clientDestinataire" reference="clients" label="Destinataire">
                                        <TextField source={"fullname"} />
                                    </ReferenceField>

                                    <ReferenceField source="adresseReception" reference="adresses" label="Adresse de livraison">
                                        <TextField source={"fullAdresse"} />
                                    </ReferenceField>

                                    <ReferenceField source="offre" reference="offres" label="Offre souscrite">
                                        <TextField source={"nom"} />
                                    </ReferenceField>

                                    <DateField source="dateLivraison" label="Date de la première livraison"/>

                                    <TextField label="Type de commande" source="typeCommande"/>

                                    <ShowButton label="Voir" />
                                    <EditButton label="Modifier" />
                                </Datagrid>
                            )}
                            {filterValues.etat === 'terminee' && (
                                <Datagrid
                                    {...props}
                                    ids={this.state['terminee']}
                                    expand={<CommandEdit />}
                                >
                                    <TextField source="idCommandeShopify" label="Id commande"/>

                                    <ReferenceField source="clientPaye" reference="clients" label="Client" >
                                        <TextField source={"fullname"} />
                                    </ReferenceField>

                                    <ReferenceField source="clientDestinataire" reference="clients" label="Destinataire">
                                        <TextField source={"fullname"} />
                                    </ReferenceField>

                                    <ReferenceField source="adresseReception" reference="adresses" label="Adresse de livraison">
                                        <TextField source={"fullAdresse"} />
                                    </ReferenceField>

                                    <ReferenceField source="offre" reference="offres" label="Offre souscrite">
                                        <TextField source={"nom"} />
                                    </ReferenceField>

                                    <DateField source="dateLivraison" label="Date de la première livraison"/>

                                    <TextField label="Type de commande" source="typeCommande"/>

                                    <ShowButton label="Voir" />
                                    <EditButton label="Modifier" />
                                </Datagrid>
                            )}
                            {filterValues.etat === 'annulee' && (
                                <Datagrid
                                    {...props}
                                    ids={this.state['annulee']}
                                    expand={<CommandEdit />}
                                >
                                    <TextField source="idCommandeShopify" label="Id commande"/>

                                    <ReferenceField source="clientPaye" reference="clients" label="Client" >
                                        <TextField source={"fullname"} />
                                    </ReferenceField>

                                    <ReferenceField source="clientDestinataire" reference="clients" label="Destinataire">
                                        <TextField source={"fullname"} />
                                    </ReferenceField>

                                    <ReferenceField source="adresseReception" reference="adresses" label="Adresse de livraison">
                                        <TextField source={"fullAdresse"} />
                                    </ReferenceField>

                                    <ReferenceField source="offre" reference="offres" label="Offre souscrite">
                                        <TextField source={"nom"} />
                                    </ReferenceField>

                                    <DateField source="dateLivraison" label="Date de la première livraison"/>

                                    <TextField label="Type de commande" source="typeCommande"/>

                                    <ShowButton label="Voir" />
                                    <EditButton label="Modifier" />
                                </Datagrid>
                            )}
                        </div>
                    }
                />
            </Fragment>
        );
    }
}

const StyledTabbedDatagrid = withStyles(datagridStyles)(TabbedDatagrid);

const CommandeList = ({ classes, ...props }) => (
    <List
        {...props}
        filterDefaultValues={{ etat: 'enregistree' }}
        sort={{ field: 'date', order: 'DESC' }}
        pagination={ <Pagination rowsPerPageOptions={[5, 10, 20, 35, 50]} /> }
        perPage={20}
        filters={<CommandFilter />}
    >
        <StyledTabbedDatagrid />
    </List>
);

export default (CommandeList);

