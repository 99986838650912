// admin/src/Component/Parametre/Show.js
import React from 'react';
import { Show, SimpleShowLayout, TextField } from 'react-admin';

export const ParametreShow = (props) => (
    <Show { ...props }>
        <SimpleShowLayout>
            <TextField source="nom" label="nom" />
            <TextField source="valeur" label="Valeur"/>
        </SimpleShowLayout>
    </Show>
);