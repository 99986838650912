// admin/src/Component/Livraison/Edit.js

import React from 'react';
import { Edit, SimpleForm, AutocompleteInput, TextInput, LongTextInput, DateInput, ReferenceInput, SelectInput} from 'react-admin';

const livraisonDefaultValue = { fleuriste: null, livreur: null };

export const LivraisonEdit = (props) => (
    <Edit {...props}>
        <SimpleForm defaultValue={livraisonDefaultValue}>
            {/*<ReferenceInput source="commande" reference="commandes" label="Commande" allowEmpty={true}>*/}
                {/*<SelectInput optionText="id" />*/}
            {/*</ReferenceInput>*/}



            <DateInput source="date" label="Date de la livraison"/>

            <SelectInput label="Etat de la livraison" source="etatgeneral" choices={[
                { id: 'avenir', name: 'avenir' },
                { id: 'probleme', name: 'probleme' },
                { id: 'terminee', name: 'terminee' },
                { id: 'annulee', name: 'annulee' },
            ]}
            />

            <TextInput source={"creneau"} label="Créneau"/>

            <TextInput source="petitMot" label="Petit mot"/>

            <TextInput source="telContact" label="Téléphone destinataire"/>


            <SelectInput label="Etat contact" source="etatcontact" allowEmpty={true} choices={[
                { id: 'SMS ENVOYE', name: 'SMS ENVOYE' },
                { id: 'SMS ECHEC', name: 'SMS ECHEC' },
            ]}
            />

            <ReferenceInput source="fleuriste" reference="fleuristes" label="Fleuriste">
                <SelectInput optionText="nom" />
            </ReferenceInput>

            <LongTextInput source="infosSuppFleuriste" label="Infos supplémentaires fleuriste"/>

            <SelectInput label="Etat mission fleuriste" source="etatmissionfleuriste" allowEmpty={true} choices={[
                { id: 'MAIL ENVOYE', name: 'MAIL ENVOYE' },
                { id: 'ACCEPTEE', name: 'ACCEPTEE' },
                { id: 'PRET A LIVRER', name: 'PRET A LIVRER' },
                { id: 'TERMINEE', name: 'TERMINEE' },
                { id: 'PROBLEME', name: 'PROBLEME' },
            ]}
            />


            <ReferenceInput source="livreur" reference="livreurs" label="Livreur">
                <SelectInput optionText="nom" />
            </ReferenceInput>

            <LongTextInput source="infosSuppLivraison" label="Infos supplémentaires livreur"/>

            <SelectInput label="Etat mission livreur" source="etatlivraison" allowEmpty={true} choices={[
                { id: 'DISPATCHE', name: 'DISPATCHE' },
                { id: 'MAIL ENVOYE', name: 'MAIL ENVOYE' },
                { id: 'ACCEPTEE', name: 'ACCEPTEE' },
                { id: 'EN COURS', name: 'EN COURS' },
                { id: 'TERMINEE', name: 'TERMINEE' },
                { id: 'PROBLEME', name: 'PROBLEME' },
            ]}
            />


            <ReferenceInput source="destinataire" reference="clients" label="Client destinataire" allowEmpty={true} >
                <AutocompleteInput optionText="fullname" />
            </ReferenceInput>

            <ReferenceInput source="adresseLivraison" reference="adresses" label="Adresse de livraison" allowEmpty={true} >
                <AutocompleteInput optionText="fullAdresse" />
            </ReferenceInput>





            <ReferenceInput source="produit" reference="produits" label="Produit à livrer">
                <SelectInput optionText="nom" />
            </ReferenceInput>



        </SimpleForm>
    </Edit>
);