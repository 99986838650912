// in src/ImportAction.js
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { showNotification } from 'react-admin';
import { push } from 'react-router-redux';

const request_uri = `${process.env.REACT_APP_API_ENTRYPOINT}/import`;

class ImportAction extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: '',
        };

        this.fileInput = React.createRef();
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    handleDataChange = (evt) => {
        this.setState({ data: evt.target.value });
    }
    handleSubmit (e) {

        e.preventDefault();

        const { push, showNotification }  = this.props;

        const data = this.fileInput.current.files[0];
        const token = localStorage.getItem('token');

        let form = new FormData();
        form.append('orders_export', data);

        const headers = new Headers({'Authorization': 'Bearer ' + token });


        fetch(`${request_uri}`, { method: 'POST', headers: headers, body: form })
            .then(response =>
                response.json().then(data => ({
                        data: data,
                        status: response.status
                    })
                ).then(res => {

                    if (res.data.length > 0) {
                        console.log("on a un resultat " + res.data);
                        showNotification('Succes, le fichier contenant ' + (res.data.length - 1) + ' commandes a bien été traité.');
                        push('/livraisons');
                    }
                    else {
                        console.log("on a un echec");
                        showNotification('Erreur: échec de l\'import', 'warning');
                    }


                    }
                )).catch((e) => {
                console.error(e);
                showNotification('Erreur: échec de l\'import', 'warning');
            });
    }

    render() {
        const { data } = this.state;
        const enabled =
            data.length > 0;
        return (
        <form>
            <br/>
            <input type="file" ref={this.fileInput} name="file" accept=".csv" onChange={this.handleDataChange} /><br/><br/>
            <Button variant="contained"  disabled={!enabled} color="primary"  label="Import" onClick={this.handleSubmit}>Envoyer</Button>
        </form>);
    }
}

ImportAction.propTypes = {
    push: PropTypes.func,
    showNotification: PropTypes.func,
};

export default connect(null, {
    showNotification,
    push,
})(ImportAction);