import { hydraClient, fetchHydra as baseFetchHydra  } from '@api-platform/admin';

const fetchHydra = (url, options = {}) => {
    const fetchHeaders = {'Authorization': `Bearer ${window.localStorage.getItem('token')}`};
    return baseFetchHydra(url, {
        ...options,
        headers: new Headers(fetchHeaders),
    });
}
const dataProvider = api => hydraClient(api, fetchHydra);

export default dataProvider;
