// in src/SmsDailySenderAction.js

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { showNotification } from 'react-admin';

const request_uri = `${process.env.REACT_APP_API_ENTRYPOINT}/smsSender/`;

class SmsDailySenderAction extends Component {
    constructor(props) {
        super(props);
        this.state = {
            periode: '',
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleSubmit (e) {

        e.preventDefault();

        const { showNotification }  = this.props;

        const periode = this.state.periode;
        const token = localStorage.getItem('token');

        const headers = new Headers({'Authorization': 'Bearer ' + token });


        fetch(`${request_uri}${periode}`, { method: 'GET', headers: headers })
            .then(response =>
                response.json().then(data => ({
                        data: data,
                        status: response.status
                    })
                ).then(res => {

                        if (res.data["La reponse"].match("^Tous les sms sont partis")) {
                            console.log("on a un resultat " + res.data["La reponse"]);
                            showNotification(res.data["La reponse"]);
                        }
                        else {
                            console.log("on a un echec");
                            showNotification('Erreur: échec d\'envoi des Sms', 'warning');
                        }


                    }
                )).catch((e) => {
            console.error(e);
            showNotification('Erreur: échec d\'envoi des Sms', 'warning');
        });
    }

    render() {

        const { periode } = this.state;
        const enabled =
            periode.length > 0;

        return (
        <form>
            <Select
                value={this.state.periode}
                onChange={this.handleChange}
                inputProps={{
                    name: 'periode'
                }}
            >
                <MenuItem value=""></MenuItem>
                <MenuItem value={'avenir'}>Au delà d'une semaine</MenuItem>
                <MenuItem value={'semaine'}>Dans la semaine</MenuItem>
                <MenuItem value={'troisjours'}>Dans trois jours</MenuItem>
                <MenuItem value={'deuxjours'}>Dans deux jours</MenuItem>
                <MenuItem value={'demain'}>Demain</MenuItem>
                <MenuItem value={'aujourdhui'}>Aujourd'hui</MenuItem>
            </Select>
            <br/><br/>
            <Button variant="contained" disabled={!enabled} color="primary"  label="Envoi des SMS" onClick={this.handleSubmit}>Envoi des SMS</Button>
        </form>);
    }
}

SmsDailySenderAction.propTypes = {
    showNotification: PropTypes.func,
};

export default connect(null, {
    showNotification,
})(SmsDailySenderAction);