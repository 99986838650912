// in src/ExportAction.js
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { showNotification } from 'react-admin';
import { push } from 'react-router-redux';
import FileSaver from 'file-saver';

const request_uri = `${process.env.REACT_APP_API_ENTRYPOINT}/livreursExport/`;

class ExportLivreurAction extends Component {
    constructor(props) {
        super(props);
        this.state = {
            periode: '',
            livreur: '',
        };

        // this.fileInput = React.createRef();
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    // handleDataChange = (evt) => {
    //     this.setState({ data: evt.target.value });
    // }
    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    extractFileName = (contentDispositionValue) => {
        let filename = "";
        if (contentDispositionValue && contentDispositionValue.indexOf('attachment') !== -1) {
            let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            let matches = filenameRegex.exec(contentDispositionValue);
            if (matches !== null && matches[1]) {
                filename = matches[1].replace(/['"]/g, '');
            }
        }
        return filename;
    };

    handleSubmit (e) {

        e.preventDefault();

        const {showNotification} = this.props;

        const periode = this.state.periode;
        const livreur = this.state.livreur;
        const token = localStorage.getItem('token');

        const headers = new Headers({'Authorization': 'Bearer ' + token});


        fetch(`${request_uri}${livreur}/${periode}`, {method: 'GET', headers: headers})

            .then(response => {
                // console.log("Response", response);
                let disposition = response.headers.get('Content-Disposition');
                // console.log(disposition);
                const filename=this.extractFileName(disposition);
                // console.log("File name",filename);

                response.blob()
                    .then(function (blob) {
                            FileSaver.saveAs(blob, filename);
                            //console.log("on a un resultat");
                            showNotification('Succes, le fichier ' + filename + ' pour la periode ' + periode + ' a bien été exporté.');
                        }
                    ).catch((e) => {
                    console.error(e);
                    showNotification('Erreur: échec de l\'export', 'warning');
                });
            });
    }

    render() {
        const { periode } = this.state;
        const enabled =
            periode.length > 0;


        return (
            <form>
                <br/>
                <Select
                    value={this.state.periode}
                    onChange={this.handleChange}
                    inputProps={{
                        name: 'periode'
                    }}
                >
                    <MenuItem value=""></MenuItem>
                    <MenuItem value={'toutes'}>Toutes</MenuItem>
                    <MenuItem value={'avenir'}>Au delà d'une semaine</MenuItem>
                    <MenuItem value={'semaine'}>Dans la semaine</MenuItem>
                    <MenuItem value={'troisjours'}>Dans trois jours</MenuItem>
                    <MenuItem value={'deuxjours'}>Dans deux jours</MenuItem>
                    <MenuItem value={'demain'}>Demain</MenuItem>
                    <MenuItem value={'aujourdhui'}>Aujourd'hui</MenuItem>

                    <MenuItem value={'terminee'}>Terminées</MenuItem>
                    <MenuItem value={'annulee'}>Annulées</MenuItem>


                </Select>
                <Select
                    value={this.state.livreur}
                    onChange={this.handleChange}
                    inputProps={{
                        name: 'livreur'
                    }}
                >
                    <MenuItem value=""></MenuItem>
                    <MenuItem value={'Couriier'}>Couriier</MenuItem>
                    <MenuItem value={'misterpasha'}>Mister Pasha</MenuItem>

                </Select>

                    <br/><br/>

                <Button variant="contained"  disabled={!enabled}  color="primary"  label="Export" onClick={this.handleSubmit}>Envoyer</Button>
            </form>);
        }

}

ExportLivreurAction.propTypes = {
    push: PropTypes.func,
    showNotification: PropTypes.func,
};

export default connect(null, {
    showNotification,
    push,
})(ExportLivreurAction);