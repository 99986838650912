// admin/src/Component/Administrateur/Create.js

import React from 'react';
import { Create, SimpleForm, TextInput, email, required, DisabledInput } from 'react-admin';
import Typography from '@material-ui/core/Typography';

const validateEmail = [required(), email()];

export const AdministrateurCreate = (props) => (
    <Create aside={<Aside />} { ...props }>
        <SimpleForm>
            <TextInput source="email" label="Email" validate={ validateEmail } />
            <TextInput source="password" label="Mot de passe" validate={ required() } type="password" />
            <TextInput source="username" label="Nom d'Utilisateur" validate={ required() }/>
            <DisabledInput source="isActive" label="Activation" defaultValue={false} />

        </SimpleForm>
    </Create>
);

const Aside = () => (
    <div style={{ width: 200, margin: '1em' }}>
        <Typography variant="title" gutterBottom>Contraintes</Typography>
        <Typography variant="body1">
           Email
        </Typography>
        <Typography variant="caption" gutterBottom>Il doit être unique.</Typography>
        <Typography variant="body1">
            Mot de passe
        </Typography>
        <Typography variant="caption" gutterBottom>8 caractères minimum dont un chiffre et une majuscule.</Typography>
        <Typography variant="body1">
            Nom d'utilisateur
        </Typography>
        <Typography variant="caption" gutterBottom>Il doit être unique.</Typography>
    </div>
);

