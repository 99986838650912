// admin/src/Component/ModeDL/Create.js

import React from 'react';
import { Create, SimpleForm, TextInput, LongTextInput, SelectInput, required } from 'react-admin';

export const ModeDLCreate = (props) => (
    <Create { ...props }>
        <SimpleForm>
            <TextInput source="nom" label="nom" validate={required()} />
            <LongTextInput source="installation" label="Installation"/>
            <SelectInput label="Véhicule" source="vehicule" choices={[
                { id: 'CLASSIC', name: 'CLASSIC' },
                { id: 'CARGO', name: 'CARGO' },
                { id: 'SCOOTER', name: 'SCOOTER' },
                { id: 'VOITURE', name: 'VOITURE' },
            ]} />

            <LongTextInput source="descriptionFleuriste" label="Description fleuriste"/>
        </SimpleForm>
    </Create>
);

