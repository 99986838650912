// admin/src/Component/Administrateur/Edit.js

import React from 'react';
import { Edit, SimpleForm, TextInput, email, BooleanInput, ReferenceArrayInput, AutocompleteArrayInput} from 'react-admin';



export const ClientEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="adresseMail" label="Email" validate={ email() } />
            <TextInput source="prenom" label="Prénom"/>
            <TextInput source="nom" label="Nom"/>
            <TextInput source="entreprise" label="Entreprise" />
            <TextInput source="numTel" label="Téléphone"/>
            <BooleanInput source="acceptSms" label="SMS" />
            <BooleanInput source="estEntreprise" label="Pro" />

            <ReferenceArrayInput
                source="adresses_livr"
                reference="adresses"
                label="Adresses du client"
                filterToQuery={searchText => ({ fullAdresse: searchText })}
                perPage={10000}
            >
                <AutocompleteArrayInput
                    optionText="fullAdresse"
                    options={{
                        suggestionsContainerProps: { disablePortal: true, modifiers: { keepTogether: { enabled: true } } }
                    }}
                />
            </ReferenceArrayInput>

        </SimpleForm>
    </Edit>
);