// admin/src/Component/Livreur/List.js

import React from 'react';
import { Filter, TextInput, Pagination, List, Datagrid, BooleanField, TextField, EmailField, ShowButton, EditButton } from 'react-admin';

const AdminFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Nom" source="nom" />
        <TextInput label="Email" source="adresseMail" />
    </Filter>
);

export const LivreurList = (props) => (
    <List {...props} title="Livreurs" pagination={ <Pagination rowsPerPageOptions={[5, 10, 20]}/> }  perPage={ 10 } filters={<AdminFilter />}>
        <Datagrid>
            <EmailField source="adresseMail" label="Email" />
            <TextField source="nom" label="Nom"/>
            <TextField source="telephone" label="Téléphone" sortable={false}/>
            <BooleanField source="application" label="Utilise l'application"/>
            <ShowButton label="Voir" />
            <EditButton label="Modifier" />
        </Datagrid>
    </List>
);


