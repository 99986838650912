// admin/src/Component/Administrateur/Show.js
import React from 'react';
import { Show, SimpleShowLayout, TextField, DateField, EmailField, BooleanField } from 'react-admin';
import SimpleArray from '../../Utils/SimpleArray';

export const AdministrateurShow = (props) => (
    <Show { ...props }>
        <SimpleShowLayout>
            <TextField source="originId" label="ID"/>
            <EmailField source="email" label="Email" />
            <TextField source="username" label="Nom d'utilisateur"/>
            <BooleanField source="isActive" label="Activé"/>
            <SimpleArray source="roles" label="Roles" sortable={false}/>
            <DateField source="createdAt" label="Date"/>
            <DateField source="updatedAt" label="Date"/>
        </SimpleShowLayout>
    </Show>
);