import React, { Component } from 'react';
import { Login, Admin, Resource } from 'react-admin';
import parseHydraDocumentation from '@api-platform/api-doc-parser/lib/hydra/parseHydraDocumentation';

import dataProvider from './dataProvider';
import authProvider from './authProvider';
import { Dashboard } from './Components/Dashboard';
import UserIcon from '@material-ui/icons/People';
import PlaceIcon from '@material-ui/icons/Place';
import ListIcon from '@material-ui/icons/List';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import LocalFloristIcon from '@material-ui/icons/LocalFlorist';
import DirectionsBikeIcon from '@material-ui/icons/DirectionsBike';
import BuildIcon from '@material-ui/icons/Build';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import SendIcon from '@material-ui/icons/Send';
import SettingsIcon from '@material-ui/icons/Settings';
import { Redirect } from 'react-router-dom';
import { createMuiTheme } from '@material-ui/core/styles';
import {blue, pink, lime} from '@material-ui/core/colors';
//import Layout from './Component/Layout';
import {AdministrateurList, AdministrateurCreate, AdministrateurEdit, AdministrateurShow} from './Components/Administrateur';

import {ParametreList, ParametreCreate, ParametreEdit, ParametreShow} from './Components/Parametre';
import {ClientList, ClientCreate, ClientEdit, ClientShow} from './Components/Client';
import {AdresseList, AdresseCreate, AdresseEdit, AdresseShow} from './Components/Adresse';
import {ProduitList, ProduitCreate, ProduitEdit, ProduitShow} from './Components/Produit';
import {ModeDLList, ModeDLCreate, ModeDLEdit, ModeDLShow} from './Components/ModeDeLivraison';
import {OffreList, OffreCreate, OffreEdit, OffreShow} from './Components/Offre';
import {FleuristeList, FleuristeCreate, FleuristeEdit, FleuristeShow} from './Components/Fleuriste';
import {LivraisonList, LivraisonCreate, LivraisonEdit, LivraisonShow} from './Components/Livraison';
import {CommandeList, CommandeEdit, CommandeShow} from './Components/Commande';
import {LivreurList, LivreurCreate, LivreurEdit, LivreurShow} from './Components/Livreur';
import CheckAuth from './Components/Dashboard/CheckAuth';

const theme = createMuiTheme({
    palette: {
        type: 'light',
        secondary: process.env.REACT_APP_COLOR === 'blue' ? blue : process.env.REACT_APP_COLOR === 'lime' ? lime : pink
    },
});

const MyLoginPage = () => <Login backgroundImage="/background.jpg" />;

const entrypoint = process.env.REACT_APP_API_ENTRYPOINT;

const fetchHeaders = {'Authorization': `Bearer ${window.localStorage.getItem('token')}`};

const apiDocumentationParser = entrypoint => parseHydraDocumentation(entrypoint, { headers: new Headers(fetchHeaders) })
    .then(
        ({ api }) => ({api}),
        (result) => {
            switch (result.status) {
                case 401:
                    return Promise.resolve({
                        api: result.api,
                        customRoutes: [{
                            props: {
                                path: '/',
                                render: () => <Redirect to={`/login`}/>,
                            },
                        }],
                    });

                default:
                    return Promise.reject(result);
            }
        },
    );

export default class extends Component {
    state = { api: null };

    componentDidMount() {
        apiDocumentationParser(entrypoint).then(({ api }) => {
            this.setState({ api });
        }).catch((e) => {
            console.log(e);
        });
    }

    render() {
        if (null === this.state.api) return <div>Loading...</div>;
        return (
            <Admin
                   loginPage={MyLoginPage}
                   title="Admin Fleurs d'Ici"
                   dashboard={Dashboard}
                   api={ this.state.api }
                   apiDocumentationParser={ apiDocumentationParser }
                   dataProvider= { dataProvider(this.state.api) }
                   theme={ theme }
                   //appLayout={ Layout }
                   authProvider={ authProvider }
            >
                <Resource name="parametres" list={ ParametreList } create={ ParametreCreate } show={ ParametreShow } edit={ ParametreEdit } title="Parametres" icon={SettingsIcon}/>
                <Resource name="administrateurs" list={ AdministrateurList } create={ AdministrateurCreate } show={ AdministrateurShow } edit={ AdministrateurEdit } title="Administrateurs" icon={UserIcon}/>

                <Resource name="produits" list={ ProduitList } create={ ProduitCreate } show={ ProduitShow } edit={ ProduitEdit } title="Produits" icon={ListIcon}/>
                <Resource name="mode_de_livraisons" list={ ModeDLList } create={ ModeDLCreate } show={ ModeDLShow } edit={ ModeDLEdit } title="Modes De Livraison" icon={BuildIcon}/>
                <Resource name="offres" list={ OffreList } create={ OffreCreate } show={ OffreShow } edit={ OffreEdit } title="Offres" icon={LocalOfferIcon}/>

                <Resource name="fleuristes" list={ FleuristeList } create={ FleuristeCreate } show={ FleuristeShow } edit={ FleuristeEdit } title="Fleuristes" icon={LocalFloristIcon}/>
                <Resource name="livreurs" list={ LivreurList } create={ LivreurCreate } show={ LivreurShow } edit={ LivreurEdit } title="Livreurs" icon={DirectionsBikeIcon}/>

                <Resource name="clients" list={ ClientList } create={ ClientCreate } show={ ClientShow } edit={ ClientEdit } title="Clients" icon={UserIcon}/>
                <Resource name="adresses" list={ AdresseList } create={ AdresseCreate } show={ AdresseShow } edit={ AdresseEdit } title="Adresses" icon={PlaceIcon}/>


                <Resource name="commandes" list={ CommandeList } show={ CommandeShow } edit={ CommandeEdit } title="Commandes" icon={ShoppingCartIcon}/>
                <Resource name="livraisons" list={ LivraisonList } create={ LivraisonCreate } show={ LivraisonShow } edit={ LivraisonEdit } title="Livraisons" icon={SendIcon}/>
                <CheckAuth/>

            </Admin>
        )
    }
}
