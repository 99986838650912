import { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { showNotification } from 'react-admin';
import decodeJwt from 'jwt-decode';

class checkAuth extends Component {
    constructor(props) {
        super(props);
        this.checkAuth();
    }

    checkAuth = () => {
        console.log('check auth');
        const decodedToken = localStorage.getItem('token') ? decodeJwt(localStorage.getItem('token')) : false;
        if (decodedToken) {
            let timeToLive = decodedToken.exp*1000 - new Date().getTime();
            if( timeToLive < 0 ) {
                this.props.showNotification('Session expirée, il faut se reconnecter', 'warning');
                setTimeout(this.checkAuth, 30000);
            } else {
                setTimeout(this.checkAuth, timeToLive + 1000 );
            }
        } else {
            setTimeout(this.checkAuth, 30000);
        }
    };

    render(){ return '' }
}

checkAuth.propTypes = {
    showNotification: PropTypes.func,
};

export default connect(null, {
    showNotification,
})(checkAuth);
