import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, crudUpdateMany, showNotification } from 'react-admin';
import FileSaver from 'file-saver';

const request_uri = `${process.env.REACT_APP_API_ENTRYPOINT}/fleetersExport/`;

class ExportFleetersButton extends Component {
    extractFileName = (contentDispositionValue) => {
        let filename = "";
        if (contentDispositionValue && contentDispositionValue.indexOf('attachment') !== -1) {
            let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            let matches = filenameRegex.exec(contentDispositionValue);
            if (matches !== null && matches[1]) {
                filename = matches[1].replace(/['"]/g, '');
            }
        }
        return filename;
    };

    handleClick = () => {
        const { selectedIds } = this.props;
        let ids = selectedIds.map(c => c.replace('/livraisons/', ''));

        const headers = new Headers({'Authorization': 'Bearer ' + localStorage.getItem('token')});
        fetch(`${request_uri}[${ids}]`, {method: 'GET', headers: headers})

            .then(response => {
                let disposition = response.headers.get('Content-Disposition');
                const filename=this.extractFileName(disposition);

                response.blob()
                    .then(function (blob) {
                            FileSaver.saveAs(blob, filename);
                            showNotification('Succes, le fichier ' + filename + ' pour les livraisons sélectionnées a bien été exporté.');
                        }
                    ).catch((e) => {
                    console.error(e);
                    showNotification('Erreur: échec de l\'export', 'warning');
                });
            });
    };

    render() {
        return (
            <Button label="Export Fleeters" onClick={this.handleClick} />
        );
    }
}

export default connect(undefined, { crudUpdateMany })(ExportFleetersButton);
