// admin/src/Component/Commande/Edit.js

import React from 'react';
import { Edit, SimpleForm, TextInput, DateInput, ReferenceInput, SelectInput, AutocompleteInput, LongTextInput, required} from 'react-admin';


export const CommandeEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <ReferenceInput
                source="clientPaye"
                reference="clients"
                label="Client"
                validate={required()}
                filterToQuery={searchText => ({ fullname: searchText })}
                perPage={10000}
            >
                <AutocompleteInput
                    optionText="fullname"
                    options={{
                        suggestionsContainerProps: { disablePortal: true, modifiers: { keepTogether: { enabled: true } } }
                    }}
                />
            </ReferenceInput>

            <ReferenceInput source="clientDestinataire" reference="clients" label="Destinataire">
                <SelectInput optionText="fullname" />
            </ReferenceInput>

            <ReferenceInput source="adresseFacturation" reference="adresses" label="Adresse de facturation">
                <SelectInput optionText="fullAdresse" />
            </ReferenceInput>

            <ReferenceInput source="adresseReception" reference="adresses" label="Adresse de livraison">
                <SelectInput optionText="fullAdresse" />
            </ReferenceInput>

            <ReferenceInput source="offre" reference="offres" label="Offre souscrite" perPage={100}>
                <SelectInput optionText="nom" />
            </ReferenceInput>

            <TextInput source="creneauLivraison" label="Créneau"/>

            <TextInput source="petitMot" label="Petit Mot"/>

            <DateInput source="dateLivraison" label="Date de la première livraison"/>

            <SelectInput label="Type de commande" source="typeCommande" choices={[
                { id: 'B2C', name: 'B2C' },
                { id: 'B2B', name: 'B2B' },
                { id: 'B2B_Cadeau', name: 'B2B_Cadeau' },
            ]}
            />

            <SelectInput label="Etat de la commande" source="etat" choices={[
                { id: 'enregistree', name: 'enregistree' },
                { id: 'encours', name: 'encours' },
                { id: 'terminee', name: 'terminee' },
                { id: 'annulee', name: 'annulee' },
            ]}
            />

            <LongTextInput source="annotationsFleursdici" label="À propos de cette commande" />

        </SimpleForm>
    </Edit>
);