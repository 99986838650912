import React from "react";
import PropTypes from 'prop-types';

function populateList(numbers) {
    return numbers.map((number) =>
            <div key={number}>
            {number}
            </div>
    );
}

const SimpleArray = ({source, record = {}}) =>
    <div>
        {
            populateList(record[source])
        }
    </div>;


SimpleArray.defaultProps = {
    addLabel: true,
    label: 'List'
};


SimpleArray.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string
};

export default SimpleArray;