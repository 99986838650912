// in src/SmsDailySenderAction.js
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { showNotification, TextInput, ReferenceInput, AutocompleteInput, LongTextInput, SelectInput } from 'react-admin';
import { reduxForm } from 'redux-form';
import { push } from 'react-router-redux';

const formatIriAsInt = v => {
    if (v.indexOf('/') >= 0) {
        v = v.split('/');

        return parseInt(v[v.length - 1]);
    }

    return v;
};

// const formatIrisAsSource = v => {
//         v = v.split('/');
//         return v[0];
// };

const request_uri = `${process.env.REACT_APP_API_ENTRYPOINT}/createcommande`;

class CreateCommandeAction extends Component {
    constructor(props) {
        super(props);
        this.state = {
            adresselivraison: '',
            clientRecoit: '',
            adressefacturation: '',
            clientPaye: '',
            offre: '',
            petitMot: '',
            date: '',
            creneau: '',
            type: '',
            acceptsms: true,
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }


    handleChange = event => {
        this.setState({ [event.target.id]: event.target.value });
    };

    handleSelectChange = id => (event) => {
        let array = Object.values(event);
        array.pop();
        let newarray = array.join('');
        //console.log(newarray);
        this.setState({ type: newarray });
    };


    handleCheckboxChange = name => event => {
        this.setState({ [name]: event.target.checked });
    };

    handleEntity = source => (event) => {
         //let value = formatIriAsInt(event);
         let array = Object.values(event);
         array.pop();
         array.shift();
         let newarray = array.join('');
         //console.log(newarray);
         let value = formatIriAsInt(newarray);
         //let source = formatIrisAsSource(newarray);
         //console.log(value + ' // ' + source); // the name of the form element
         //console.log(event.value); // the value of the form element
         this.setState({ [source]: value });
         //console.log(`Nouvelle entity ${event.source}  // ${event.value}`)
     };

    handleSubmit (e) {

        e.preventDefault();

        const { push, showNotification }  = this.props;

       // const { expanded, adresselivraisonvoie, adresselivraisoncp, adresselivraisonville, adresselivraisonpays, adresselivraisoninfosup, clientRecoitPrenom, clientRecoitNom, clientRecoitNumTel, clientRecoitEntreprise, clientRecoitEmail, clientRecoitEstEntreprise, clientRecoitAcceptSms, adressefacturationvoie, adressefacturationcp, adressefacturationville, adressefacturationpays, adressefacturationinfosup, clientPayePrenom, clientPayeNom, clientPayeNumTel, clientPayeEntreprise, clientPayeEmail, clientPayeEstEntreprise, clientPayeAcceptSms, offreSKU, petitMot, date, creneau, acceptsms} = this.state;
        const token = localStorage.getItem('token');

        let form = JSON.stringify(this.state);

        const headers = new Headers({'Authorization': 'Bearer ' + token });


        fetch(`${request_uri}`, { method: 'POST', headers: headers, body: form })
            .then(response =>
                response.json().then(data => ({
                        data: data,
                        status: response.status
                    })
                ).then(res => {

                        if (res.data["La reponse"].match("^commande added")) {
                            console.log("on a un resultat " + res.data["La reponse"]);
                            showNotification('Succès, la commande a été créée.', 'info');
                            push('/commandes');
                        }
                        else {
                            console.log("on a un echec");
                            showNotification('Erreur: échec de création de la commande', 'warning');
                        }


                    }
                )).catch((e) => {
            console.error(e);
            showNotification('Erreur: échec de création de la commande', 'warning');
        });
    }

    render() {

       // const { expanded, adresselivraisonvoie, adresselivraisoncp, adresselivraisonville, adresselivraisonpays, adresselivraisoninfosup, clientRecoitPrenom, clientRecoitNom, clientRecoitNumTel, clientRecoitEntreprise, clientRecoitEmail, clientRecoitEstEntreprise, clientRecoitAcceptSms, adressefacturationvoie, adressefacturationcp, adressefacturationville, adressefacturationpays, adressefacturationinfosup, clientPayePrenom, clientPayeNom, clientPayeNumTel, clientPayeEntreprise, clientPayeEmail, clientPayeEstEntreprise, clientPayeAcceptSms, offreSKU, petitMot, date, creneau, acceptsms } = this.state;
     //   const { expanded } = this.state;

        //const { classes } = this.props;

        return (
        <form>

                <ReferenceInput
                    resource="clientRecoit"
                    source="clientRecoit"
                    id="clientRecoit"
                    reference="clients"
                    label="Client destinataire"
                    // validate={required()}
                    filterToQuery={searchText => ({ fullname: searchText })}
                    perPage={10000}
                    onChange={this.handleEntity('clientRecoit')}
                    resettable={true}
                    allowEmpty={true}

                >
                    <AutocompleteInput
                        optionText="fullname"
                        options={{
                            suggestionsContainerProps: { disablePortal: true, modifiers: { keepTogether: { enabled: true } } }
                        }}
                    />
                </ReferenceInput>
<br />

            <ReferenceInput
                resource="adresselivraison"
                source="adresselivraison"
                id="adresselivraison"
                reference="adresses"
                label="Adresse de livraison"
                // validate={required()}
                filterToQuery={searchText => ({ fullAdresse: searchText })}
                perPage={10000}
                onChange={this.handleEntity('adresselivraison')}
                resettable={true}
                allowEmpty={true}
            >
                <AutocompleteInput
                    optionText="fullAdresse"
                    options={{
                        suggestionsContainerProps: { disablePortal: true, modifiers: { keepTogether: { enabled: true } } }
                    }}
                />
            </ReferenceInput>
<br />
                    <Typography>Client payeur et/ou adresse de facturation (facultatif) :</Typography>


                    <ReferenceInput
                        resource="clientPaye"
                        source="clientPaye"
                        id="clientPaye"
                        reference="clients"
                        label="Client payeur"
                        // validate={required()}
                        filterToQuery={searchText => ({ fullname: searchText })}
                        perPage={10000}
                        onChange={this.handleEntity('clientPaye')}
                        resettable={true}
                        allowEmpty={true}
                    >
                        <AutocompleteInput
                            optionText="fullname"
                            options={{
                                suggestionsContainerProps: { disablePortal: true, modifiers: { keepTogether: { enabled: true } } }
                            }}
                        />
                    </ReferenceInput>

            <br/>


                    <ReferenceInput
                        resource="adressefacturation"
                        source="adressefacturation"
                        id="adressefacturation"
                        reference="adresses"
                        label="Adresse de facturation"
                        //  validate={required()}
                        filterToQuery={searchText => ({ fullAdresse: searchText })}
                        perPage={10000}
                        onChange={this.handleEntity('adressefacturation')}
                        resettable={true}
                        allowEmpty={true}
                    >
                        <AutocompleteInput
                            optionText="fullAdresse"
                            options={{
                                suggestionsContainerProps: { disablePortal: true, modifiers: { keepTogether: { enabled: true } } }
                            }}
                        />
                    </ReferenceInput>

            <br/>

            <ReferenceInput
                resource="offre"
                source="offre"
                id="offre"
                reference="offres"
                label="Offre souscrite"
                //validate={required()}
                filterToQuery={searchText => ({ nom: searchText })}
                perPage={10000}
                onChange={this.handleEntity('offre')}
                resettable={true}
                allowEmpty={true}
            >
                <AutocompleteInput
                    optionText="nom"
                    options={{
                        suggestionsContainerProps: { disablePortal: true, modifiers: { keepTogether: { enabled: true } } }
                    }}
                />
            </ReferenceInput>


            <LongTextInput
                source="petitMot"
                id="petitMot"
                label="Petit mot"
                value={this.state.petitMot}
                onChange={this.handleChange}
                resettable
                allowEmpty
            />
            <br />
            <TextInput
                source="creneau"
                id="creneau"
                label="Creneau"
                value={this.state.creneau}
                onChange={this.handleChange}
            />
            <br />
            <TextField
                id="date"
                label="Date de la première livraison"
                value={this.state.date}
                onChange={this.handleChange}
                type="date"
                style = {{width: 250}}
            />
            <br />
            <FormControlLabel
                control={
                    <Checkbox
                        onChange={this.handleCheckboxChange('acceptsms')}
                        value="acceptsms"
                        checked={this.state.acceptsms}
                    />}
                label="Accepte SMS"
            />
            <br />
            <SelectInput
                source="type"
                id="type"
                label="Type de commande"
                //optionValue="id"
                //value={this.state.type}
                onChange={this.handleSelectChange()}
                choices={[
                { id: 'B2C', name: 'B2C' },
                { id: 'B2B', name: 'B2B' },
                { id: 'B2B_Cadeau', name: 'B2B_Cadeau' },
            ]}
            />
            <br />
            <Button variant="contained" color="primary"  label="Créer ue commande" onClick={this.handleSubmit}>Créer une commande</Button>

            <br />
            <br />
            <br />
            <br />

        </form>

        );
    }
}

CreateCommandeAction.propTypes = {
    showNotification: PropTypes.func,
    push: PropTypes.func,
};

// Decorate the form component
CreateCommandeAction = reduxForm({
    form: 'createsimplecommande' // a unique name for this form
})(CreateCommandeAction);

export default connect(null, {
    showNotification,
    push,
})(CreateCommandeAction);