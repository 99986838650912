// admin/src/Component/Client/List.js

import React from 'react';
import { Filter, TextInput, Pagination, List, Datagrid, TextField, NumberField, ReferenceField, ShowButton, EditButton, NumberInput, ReferenceInput, SelectInput } from 'react-admin';
//import { CustomPagination } from '../Pagination/CustomPagination';

const AdminFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Nom" source="nom" />
        <TextInput label="Sku" source="sku" />
        <NumberInput label="Durée" source="duree" />
        <NumberInput label="Fréquence" source="frequence" />
        <ReferenceInput label="Produit" source="produit" reference="produits" perPage={100}>
            <SelectInput optionText="nom" />
        </ReferenceInput>
    </Filter>
);

export const OffreList = (props) => (
    <List {...props} title="Offres" pagination={ <Pagination rowsPerPageOptions={[5, 10, 35]}/> }  perPage={ 35 } filters={<AdminFilter />}>
        <Datagrid>
            <TextField source="nom" label="Nom" />
            <NumberField source="prix" label="Prix"/>
            <NumberField source="frequence" label="Fréquence"/>
            <NumberField source="duree" label="Durée"/>
            <ReferenceField source="produit" reference="produits" label="Produit">
                <TextField source={"nom"}/>
            </ReferenceField>
            <TextField source="sku" label="SKU"/>

            <ShowButton label="Voir" />
            <EditButton label="Modifier" />
        </Datagrid>
    </List>
);


