// admin/src/Component/ModeDL/Edit.js

import React from 'react';
import { Edit, SimpleForm, TextInput, LongTextInput, required, SelectInput} from 'react-admin';



export const ModeDLEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="nom" label="nom" validate={required()} />
            <LongTextInput source="installation" label="Installation"/>
            <SelectInput label="Véhicule" source="vehicule" choices={[
                { id: 'CLASSIC', name: 'CLASSIC' },
                { id: 'CARGO', name: 'CARGO' },
                { id: 'SCOOTER', name: 'SCOOTER' },
                { id: 'VOITURE', name: 'VOITURE' },
            ]} />

            <LongTextInput source="descriptionFleuriste" label="Description fleuriste"/>
        </SimpleForm>
    </Edit>
);