// admin/src/Component/Adresse/List.js

import React from 'react';
import { Filter, TextInput, Pagination, List, Datagrid, TextField, ShowButton, EditButton } from 'react-admin';
//import { CustomPagination } from '../Pagination/CustomPagination';

const AdminFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Adresse" source="fullAdresse" />
    </Filter>
);

export const AdresseList = (props) => (
    <List {...props} title="Adresses" pagination={ <Pagination rowsPerPageOptions={[5, 10, 20]}/> }  perPage={ 20 } filters={<AdminFilter />}>
        <Datagrid>
            <TextField source="voie" label="Voie" />
            <TextField source="ville" label="Ville"/>
            <TextField source="codePostal" label="Code Postal"/>
            <TextField source="pays" label="Pays"/>
            <TextField source="infoSup" label="Infos" sortable={false}/>
            <ShowButton label="Voir" />
            <EditButton label="Modifier" />
        </Datagrid>
    </List>
);


