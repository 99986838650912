// admin/src/Component/Client/List.js

import React from 'react';
import { Show, SimpleShowLayout, Filter, BooleanField, BooleanInput, TextInput, Pagination, List, Datagrid, TextField, NumberField, ShowButton, EditButton } from 'react-admin';
//import { CustomPagination } from '../Pagination/CustomPagination';

const AdminFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Nom" source="nom" />
        <TextInput label="Code Produit" source="codeproduit" />
        <TextInput label="Indication de taille" source="indicationtaille"/>
        <TextInput label="Composition" source="composition" />
        <BooleanInput label="Avec vase" source="vase" />
    </Filter>
);


const ProduitPanel =  props => (

    <Show
        {...props}
        /* disable the app title change when shown */
        title=" ">
<SimpleShowLayout>

        <TextField source="codeproduit" label="Code Produit" />

    <NumberField source="bonusexpress" label="Bonus Express" />
        <TextField source="indicationtaille" label="Indication taille" />

</SimpleShowLayout>
    </Show>


);

export const ProduitList = (props) => (
    <List {...props} title="Produits" pagination={ <Pagination rowsPerPageOptions={[5, 10, 20]}/> }  perPage={ 20 } filters={<AdminFilter />}>
        <Datagrid
            expand={<ProduitPanel />}
        >
            <TextField source="nom" label="Nom" />
            <NumberField source="budgetfleuriste" label="Budget Fleuriste"/>
            <NumberField source="budgetproducteur" label="Budget Producteur"/>
            <TextField source="composition" label="Composition" />
            <BooleanField source="vase" label="vase" />
            {/*<TextField source="descriptionFleuriste" label="Description Fleuriste"/>*/}
            {/*<TextField source="descriptionLivreur" label="Description Livreur"/>*/}

            <ShowButton label="Voir" />
            <EditButton label="Modifier" />
        </Datagrid>
    </List>
);


