// admin/src/Component/Administrateur/Create.js

import React from 'react';
import { Create, SimpleForm, TextInput, NumberInput, required, ReferenceInput, SelectInput } from 'react-admin';

export const OffreCreate = (props) => (
    <Create { ...props }>
        <SimpleForm>
            <TextInput source="nom" label="Nom" validate={required()} />
            <NumberInput source="prix" label="Prix" validate={required()} />
            <NumberInput source="frequence" label="Fréquence" validate={required()} />
            <NumberInput source="duree" label="Durée" validate={required()} />
            <ReferenceInput source="produit" reference="produits" label="Produit" validate={required()}>
                <SelectInput optionText="nom" />
            </ReferenceInput>
            <TextInput source="sku" label="SKU" validate={required()} />
        </SimpleForm>
    </Create>
);

