// admin/src/Component/Administrateur/Edit.js

import React from 'react';
import { Edit, SimpleForm, TextInput} from 'react-admin';



export const AdresseEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="voie" label="Voie" />
            <TextInput source="ville" label="Ville"/>
            <TextInput source="codePostal" label="Code Postal"/>
            <TextInput source="pays" label="Pays"/>
            <TextInput source="infoSup" label="Infos"/>
            <TextInput source="latitude" label="Latitude"/>
            <TextInput source="longitude" label="Longitude"/>
        </SimpleForm>
    </Edit>
);
