// admin/src/Component/Administrateur/Show.js
import React from 'react';
import { Show, SimpleShowLayout, TextField, NumberField, ReferenceField } from 'react-admin';

export const OffreShow = (props) => (
    <Show { ...props }>
        <SimpleShowLayout>
            <TextField source="nom" label="Nom" />
            <NumberField source="prix" label="Prix"/>
            <NumberField source="frequence" label="Fréquence"/>
            <NumberField source="duree" label="Durée"/>
            <ReferenceField source="produit" reference="produits" label="Produit">
                <TextField source={"nom"}/>
            </ReferenceField>
            <ReferenceField source="modeDeLivraison" reference="mode_de_livraisons" label="Mode de livraison">
                <TextField source={"nom"}/>
            </ReferenceField>
            <TextField source="sku" label="SKU"/>
        </SimpleShowLayout>
    </Show>
);