// admin/src/Component/Administrateur/Create.js

import React from 'react';
import { Create, SimpleForm, TextInput, BooleanInput, email, required } from 'react-admin';

const validateEmail = [required(), email()];

export const ClientCreate = (props) => (
    <Create { ...props }>
        <SimpleForm>
            <TextInput source="adresseMail" label="Email" validate={ validateEmail } />
            <TextInput source="prenom" label="Prénom" validate={ required() }/>
            <TextInput source="nom" label="Nom" validate={ required() }/>
            <TextInput source="entreprise" label="Entreprise" />
            <TextInput source="numTel" label="Téléphone" validate={ required() } />
            <BooleanInput source="acceptSms" label="SMS" defaultValue={true}/>
            <BooleanInput source="estEntreprise" label="Pro" defaultValue={false}/>
        </SimpleForm>
    </Create>
);

