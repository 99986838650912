// admin/src/Component/Livraison/List.js
// admin/src/Component/Livraison/List.js

import React, { Fragment } from 'react';
import {
    Datagrid,
    SelectInput,
    ReferenceField,
    DateField,
    DateInput,
    EditButton,
    ShowButton,
    Filter,
    List,
    ReferenceInput,
    Responsive,
    TextField,
    Pagination,
    Edit,
    SimpleForm,
    TextInput,
    BulkDeleteButton
} from 'react-admin';

import rowStyle from './rowStyle';

import withStyles from '@material-ui/core/styles/withStyles';
//import Icon from '@material-ui/icons/AttachMoney';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import moment from 'moment';

import ExportFleetersButton from './ExportFleeters';

//import NbItemsField from './NbItemsField';
//import CustomerReferenceField from '../visitors/CustomerReferenceField';
//import MobileGrid from './MobileGrid';

//export const CommandIcon = Icon;

const filterStyles = {
    status: { width: 50 },
};


const todayStart =  moment(new Date()).startOf('day').format();
console.log('today start : ' + todayStart);

const todayEnd =  moment(new Date()).endOf('day').format();
console.log('today end : ' + todayEnd);

const demain = moment(new Date()).add(1,'days').endOf('day').format();
console.log('demain : ' + demain);

const yesterdayStart = moment(new Date()).add(-1,'days').startOf('day').format();
console.log('hier : ' + yesterdayStart);

const deuxjours = moment(new Date()).add(2,'days').endOf('day').format();

const troisjours = moment(new Date()).add(3,'days').endOf('day').format();
const quatrejours = moment(new Date()).add(4,'days').endOf('day').format();
const cinqjours = moment(new Date()).add(5,'days').endOf('day').format();

const semaine = moment(new Date()).add(7,'days').endOf('day').format();

// Prochain dimanche
const semainenonglissante = moment(new Date()).isoWeekday(7).endOf('day').format();
console.log('fin du prochain dimanche : ' + semainenonglissante);

// Prochain dimanche S+1
const semainenonglissante2 = moment(new Date()).isoWeekday(7).endOf('day').add(1,'weeks').format();
console.log('fin du prochain dimanche en 15 : ' + semainenonglissante2);

// Semaine précédente
const lundiPrecedentDebut = moment(new Date()).isoWeekday(1).startOf('day').add(-1,'weeks').format();
const dimanchePrecedentFin = moment(new Date()).isoWeekday(7).endOf('day').add(-1,'weeks').format();

const TempoFilter = withStyles(filterStyles)(({ classes, ...props }) => (
    <Filter {...props}>

        <TextInput label="Destinataire" source="destinataire_fullname" />

        <TextInput label="Adresse de livraison" source="adresseLivraison_fullAdresse" />

        <ReferenceInput label="Produit" source="produit" reference="produits" perPage={100}>
            <SelectInput optionText="nom" />
        </ReferenceInput>

        <ReferenceInput label="Fleuriste" source="fleuriste" reference="fleuristes" perPage={100}>
            <SelectInput optionText="nom" />
        </ReferenceInput>

        <ReferenceInput label="Livreur" source="livreur" reference="livreurs" perPage={100}>
            <SelectInput optionText="nom" />
        </ReferenceInput>

        <SelectInput label="Etat général" source="etatgeneral" choices={[
            { id: 'avenir', name: 'avenir' },
            { id: 'terminee', name: 'terminee' },
            { id: 'probleme', name: 'probleme' },
            { id: 'annulee', name: 'annulee' },
        ]}
        />

        <SelectInput label="Mission livreur" source="etatlivraison" choices={[
            { id: 'ACCEPTEE', name: 'ACCEPTEE' },
            { id: 'EN COURS', name: 'EN COURS' },
            { id: 'TERMINEE', name: 'TERMINEE' },
            { id: 'PROBLEME', name: 'PROBLEME' },
        ]}
        />

        <SelectInput label="Mission fleuriste" source="etatmissionfleuriste" choices={[
            { id: 'MAIL ENVOYE', name: 'MAIL ENVOYE' },
            { id: 'ACCEPTEE', name: 'ACCEPTEE' },
            { id: 'PRET A LIVRER', name: 'PRET A LIVRER' },
            { id: 'TERMINEE', name: 'TERMINEE' },
            { id: 'PROBLEME', name: 'PROBLEME' },
        ]}
        />

    </Filter>
));

const ToutesFilter = withStyles(filterStyles)(({ classes, ...props }) => (
    <Filter {...props}>

        <TextInput label="Destinataire" source="destinataire_fullname" />

        <TextInput label="Adresse de livraison" source="adresseLivraison_fullAdresse" />

        <TextInput label="Id commande" source="commande_idCommandeShopify" />

        <ReferenceInput label="Produit" source="produit" reference="produits" perPage={100}>
            <SelectInput optionText="nom" />
        </ReferenceInput>

        <ReferenceInput label="Fleuriste" source="fleuriste" reference="fleuristes" perPage={100}>
            <SelectInput optionText="nom" />
        </ReferenceInput>

        <ReferenceInput label="Livreur" source="livreur" reference="livreurs" perPage={100}>
            <SelectInput optionText="nom" />
        </ReferenceInput>


        <DateInput label="Avant le" source="date[before]"/>

        <DateInput label="Après le" source="date[after]"/>

        <SelectInput label="Etat général" source="etatgeneral" choices={[
            { id: 'avenir', name: 'avenir' },
            { id: 'terminee', name: 'terminee' },
            { id: 'probleme', name: 'probleme' },
            { id: 'annulee', name: 'annulee' },
        ]}
        />

        <SelectInput label="Mission livreur" source="etatlivraison" choices={[
            { id: 'MAIL ENVOYE', name: 'MAIL ENVOYE' },
            { id: 'ACCEPTEE', name: 'ACCEPTEE' },
            { id: 'EN COURS', name: 'EN COURS' },
            { id: 'TERMINEE', name: 'TERMINEE' },
            { id: 'PROBLEME', name: 'PROBLEME' },
        ]}
        />

        <SelectInput label="Mission fleuriste" source="etatmissionfleuriste" choices={[
            { id: 'MAIL ENVOYE', name: 'MAIL ENVOYE' },
            { id: 'ACCEPTEE', name: 'ACCEPTEE' },
            { id: 'PRET A LIVRER', name: 'PRET A LIVRER' },
            { id: 'TERMINEE', name: 'TERMINEE' },
            { id: 'PROBLEME', name: 'PROBLEME' },
        ]}
        />

    </Filter>
));


const LivraisonPanel = (props) => (

    <Edit {...props}>



        <SimpleForm
            /* The form must have a name dependent on the record, because by default all forms have the same name */
            form={`livraison_edit_${props.id}`}
        >

            <ReferenceField source="adresseLivraison" reference="adresses" label="Adresse de livraison">
                <TextField source={"fullAdresse"} />
            </ReferenceField>

            <TextField source={"telContact"} label="Telephone"/>
            <TextField source={"creneau"} label="Créneau horaire"/>
            <TextField source={"petitMot"} label="Petit Mot"/>

            <TextField source={"infosSuppLivraison"} label="Infos supplémentaires livreur"/>
            <TextField source={"infosSuppFleuriste"} label="Infos supplémentaires fleuriste"/>

            <SelectInput label="Etat de la livraison" source="etatgeneral" choices={[
                { id: 'avenir', name: 'avenir' },
                { id: 'probleme', name: 'probleme' },
                { id: 'terminee', name: 'terminee' },
                { id: 'annulee', name: 'annulee' },
            ]}
            />
            <ReferenceInput source="fleuriste" reference="fleuristes" label="Fleuriste" resettable allowEmpty emptyValue={null}>
                <SelectInput optionText="nom" />
            </ReferenceInput>

            <SelectInput label="Etat mission fleuriste" source="etatmissionfleuriste" allowEmpty={true} choices={[
                { id: 'MAIL ENVOYE', name: 'MAIL ENVOYE' },
                { id: 'ACCEPTEE', name: 'ACCEPTEE' },
                { id: 'PRET A LIVRER', name: 'PRET A LIVRER' },
                { id: 'TERMINEE', name: 'TERMINEE' },
                { id: 'PROBLEME', name: 'PROBLEME' },
            ]}
            />

            <ReferenceInput source="livreur" reference="livreurs" label="Livreur" resettable allowEmpty emptyValue={null}>
                <SelectInput optionText="nom" />
            </ReferenceInput>

            <SelectInput label="Etat mission livreur" source="etatlivraison" allowEmpty={true} choices={[
                { id: 'DISPATCHE', name: 'DISPATCHE' },
                { id: 'MAIL ENVOYE', name: 'MAIL ENVOYE' },
                { id: 'ACCEPTEE', name: 'ACCEPTEE' },
                { id: 'EN COURS', name: 'EN COURS' },
                { id: 'TERMINEE', name: 'TERMINEE' },
                { id: 'PROBLEME', name: 'PROBLEME' },
            ]}
            />

        </SimpleForm>
    </Edit>


);



const datagridStyles = theme =>  ({
    total: { fontWeight: 'bold' },
    root: {
        flexGrow: 1,
        maxWidth: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    row: {
        maxWidth: '100%',
    },
    tabfilter: {
        maxWidth: '10px',
        minWidth: '100px',
    },
});

const LivraisonBulkActionButtons = props => (
    <Fragment>
        <ExportFleetersButton label="Export Fleeters" {...props} />
        {/* Add the default bulk delete action */}
        <BulkDeleteButton {...props} />
    </Fragment>
);


class TabbedDatagrid extends React.Component {
    state = {
        value: 2,
    };

    handleChange = (event, value) => {
        this.setState({ value });
    };

    render() {
        const { value } = this.state;
        const { classes, ...other } = this.props;

        const tabsDefs = [
            {label: "S-1", title:"Livraisons du dernier lundi au dernier dimanche, semaine précédente non glissante",
                filter: { 'date[after]': lundiPrecedentDebut, 'date[before]': dimanchePrecedentFin } },
            {label: "Hier", title:"Livraisons d'hier",
                filter: { 'date[after]': yesterdayStart, 'date[before]': todayStart } },
            {label: "Aujourd'hui", title:"Livraisons du jour",
                filter: { 'date[after]': todayStart, 'date[before]': todayEnd } },
            {label: "J+1", title:"Livraisons de demain",
                filter: { 'date[after]': todayEnd, 'date[before]': demain } },
            {label: "J+2", title:"Livraisons à J+2",
                filter: { 'date[after]': demain, 'date[before]': deuxjours} },
            {label: "J+3", title:"Livraisons à J+3",
                filter: { 'date[after]': deuxjours, 'date[before]': troisjours } },
            {label: "J+4", title:"Livraisons à J+4",
                filter: { 'date[after]': troisjours, 'date[before]': quatrejours } },
            {label: "J+5", title:"Livraisons à J+5",
                filter: { 'date[after]': quatrejours, 'date[before]': cinqjours } },
            {label: "Semaine", title:"Livraisons d'aujourd'hui à dimanche prochain, semaine en cours non glissante",
                filter: { 'date[after]': todayStart, 'date[before]': semainenonglissante } },
            {label: "Toutes", title:"Toutes les livraisons (filtres par dates disponibles)"}
        ];

        return (
            <Fragment>
                <AppBar position="static" color="default">
                <Tabs
                    value={value}
                    onChange={this.handleChange}
                    variant="scrollable"
                    scrollButtons="on"
                >
                    {tabsDefs.map((tab) => <Tab label={tab.label} classes={{ root: classes.tabfilter}} key={tab.label} />)}
                </Tabs>
                </AppBar>

                <Divider />
                <Responsive
                    medium={
                        <div className={classes.row}>
                            {tabsDefs.map((tab, i) => {
                               return (value === i && (
                            <List
                                {...other}
                                sort={{ field: 'date', order: 'ASC' }}
                                pagination={ <Pagination rowsPerPageOptions={[5, 10, 20, 30]} /> }
                                perPage={30}
                                filters={ tabsDefs[value].label === "Toutes" ? <ToutesFilter/> : <TempoFilter/>}
                                filter={ tabsDefs[value].filter }
                                title={ tabsDefs[value].title }
                                bulkActionButtons={<LivraisonBulkActionButtons />}
                            >
                                <Datagrid
                                    rowStyle={rowStyle}
                                    expand={<LivraisonPanel />}
                                >
                                    <ReferenceField source="commande" reference="commandes" label="Commande" allowEmpty={true}>
                                        <TextField source={"idCommandeShopify"} />
                                    </ReferenceField>

                                    <DateField source="date" label="Date de la livraison"/>

                                    <TextField source="etatcontact" label="Etat contact"/>

                                    <ReferenceField source="fleuriste" reference="fleuristes" label="Fleuriste" allowEmpty={true}>
                                        <TextField source={"nom"} />
                                    </ReferenceField>

                                    <TextField source={"etatmissionfleuriste"} label="Etat mission fleuriste"/>

                                    <ReferenceField source="livreur" reference="livreurs" label="Livreur" allowEmpty={true}>
                                        <TextField source={"nom"} />
                                    </ReferenceField>

                                    <TextField source={"etatlivraison"} label="Etat mission livreur"/>

                                    <ReferenceField source="destinataire" reference="clients" label="Client destinataire">
                                        <TextField source={"fullname"} />
                                    </ReferenceField>

                                    <ReferenceField source="produit" reference="produits" label="Produit à livrer">
                                        <TextField source={"nom"} />
                                    </ReferenceField>

                                    <ShowButton label="Voir" />
                                    <EditButton label="Modifier" />
                                </Datagrid>
                            </List>
                               ))
                            })}
                        </div>
                    }
                />
            </Fragment>
        );
    }
}

const StyledTabbedDatagrid = withStyles(datagridStyles)(TabbedDatagrid);

const LivraisonList = ({ classes, ...props }) => (
    <StyledTabbedDatagrid  {...props} />
);

export default (LivraisonList);
