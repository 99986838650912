// admin/src/Component/Administrateur/Show.js
import React from 'react';
import { Show, SimpleShowLayout, TextField, NumberField, BooleanField } from 'react-admin';

export const ProduitShow = (props) => (
    <Show { ...props }>
        <SimpleShowLayout>
            <TextField source="nom" label="nom" />
            <TextField source="codeproduit" label="Code Produit" />
            <NumberField source="budgetfleuriste" label="Budget Fleuriste"/>
            <NumberField source="budgetproducteur" label="Budget Producteur"/>
            <NumberField source="bonusexpress" label="Bonus Express" />
            <TextField source="composition" label="Composition" />
            <TextField source="indicationtaille" label="Indication taille" />
            <BooleanField source="vase" label="vase" />
        </SimpleShowLayout>
    </Show>
);