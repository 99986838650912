// admin/src/Component/Parametre/List.js

import React from 'react';
import { Filter, TextInput, Pagination, List, Datagrid, TextField, ShowButton, EditButton } from 'react-admin';

const AdminFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Nom" source="nom" />
    </Filter>
);

export const ParametreList = ({hasList, hasEdit, hasShow, ...props}) => (
    <List hasList={hasList} hasEdit={hasEdit} hasShow={hasShow} {...props} title="Parametres" pagination={ <Pagination rowsPerPageOptions={[5, 10, 20]}/> }  perPage={ 20 } filters={<AdminFilter />}>
        <Datagrid>
            <TextField source="nom" label="nom" />
            <TextField source="valeur" label="Valeur"/>

            <ShowButton label="Voir" />
            <EditButton label="Modifier" />
        </Datagrid>
    </List>
);


