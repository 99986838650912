// admin/src/Component/Administrateur/Create.js

import React from 'react';
import { Create, SimpleForm, TextInput, required } from 'react-admin';

export const AdresseCreate = (props) => (
    <Create { ...props }>
        <SimpleForm>
            <TextInput source="voie" label="Voie" validate={ required() }/>
            <TextInput source="ville" label="Ville" validate={ required() }/>
            <TextInput source="codePostal" label="Code Postal" validate={ required() }/>
            <TextInput source="pays" label="Pays" validate={ required() }/>
            <TextInput source="infoSup" label="Infos" />
        </SimpleForm>
    </Create>
);

