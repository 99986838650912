// admin/src/Component/Livraison/Create.js

import React from 'react';
import { Create, SimpleForm, TextInput, LongTextInput, DateInput, required, ReferenceInput, SelectInput } from 'react-admin';

export const LivraisonCreate = (props) => (
    <Create { ...props }>
        <SimpleForm>
            <ReferenceInput source="commande" reference="commandes" label="Commande">
                <SelectInput optionText="id" />
            </ReferenceInput>

            <DateInput siource="date" label="Date de la livraison"/>

            <TextInput source={"creneau"} label="Créneau"/>


            <ReferenceInput source="fleuriste" reference="fleuristes" label="Fleuriste">
                <SelectInput optionText="nom" />
            </ReferenceInput>

            <LongTextInput source="infosSuppFleuriste" label="Infos supplémentaires fleuriste"/>



            <ReferenceInput source="livreur" reference="livreurs" label="Livreur">
                <SelectInput optionText="nom" />
            </ReferenceInput>

            <LongTextInput source="infosSuppLivraison" label="Infos supplémentaires livreur"/>

            <ReferenceInput source="destinataire" reference="clients" label="Client destinataire">
                <SelectInput optionText="fullname" />
            </ReferenceInput>

            <ReferenceInput source="adresseLivraison" reference="adresses" label="Adresse de livraison" validate={required()}>
                <SelectInput optionText="fullAdresse" />
            </ReferenceInput>



            <TextInput source="telContact" label="Téléphone destinataire"/>

            <ReferenceInput source="produit" reference="produits" label="Produit à livrer">
                <SelectInput optionText="nom" />
            </ReferenceInput>



            <TextInput source="petitMot" label="Petit mot"/>
        </SimpleForm>
    </Create>
);

