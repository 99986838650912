// admin/src/Component/Parametre/Edit.js

import React from 'react';
import { Edit, SimpleForm, TextInput, TextField} from 'react-admin';



export const ParametreEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextField source="nom" label="nom" />
            <TextInput source="valeur" label="Valeur"/>
        </SimpleForm>
    </Edit>
);