// admin/src/Component/Livreur/Create.js

import React from 'react';
import { Create, SimpleForm, TextInput, BooleanInput, email, required } from 'react-admin';

const validateEmail = [required(), email()];

export const LivreurCreate = (props) => (
    <Create { ...props }>
        <SimpleForm>
            <TextInput source="adresseMail" label="Email" validate={ validateEmail } />
            <TextInput source="nom" label="Nom" validate={ required() }/>
            <TextInput source="telephone" label="Téléphone" validate={ required() } />
            <BooleanInput source="application" label="Utilise l'application (envoi de mail sinon)" defaultValue={true}/>
        </SimpleForm>
    </Create>
);

