// admin/src/Component/Livreur/Show.js
import React from 'react';
import { Show, SimpleShowLayout, TextField, EmailField, BooleanField} from 'react-admin';

export const LivreurShow = (props) => (
    <Show { ...props }>
        <SimpleShowLayout>
            <EmailField source="adresseMail" label="Email" />
            <TextField source="nom" label="Nom"/>
            <TextField source="telephone" label="Téléphone"/>
            <BooleanField source="application" label="Utilise l'application"/>
        </SimpleShowLayout>
    </Show>
);