// admin/src/Component/Administrateur/Edit.js

import React from 'react';
import { Edit, SimpleForm, DisabledInput, TextInput, email, SelectArrayInput, DateInput, BooleanInput } from 'react-admin';



export const AdministrateurEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <DisabledInput source="originId" label="ID"/>
            <TextInput source="email" label="Email" validate={ email() } />
            <TextInput source="username" label="Nom d'utilisateur"/>
            <TextInput source="password" label="Mot de passe" type="password" defaultValue={null}/>
            <BooleanInput source="isActive" label="Activé"/>
            <SelectArrayInput label="Roles" source="roles" choices={[
                { id: 'ROLE_USER', name: 'ROLE_USER' },
                { id: 'ROLE_ADMIN', name: 'ROLE_ADMIN' },
            ]} />
            <DateInput disabled source="createdAt" label="Date"/>
        </SimpleForm>
    </Edit>
);